import useTranslation from 'next-translate/useTranslation'
import { batch } from 'react-redux'

import Modal from '../../app/components/modal/Modal'
import useAppSelector from '../../../store/hooks/useAppSelector'
import Button from '../../app/components/Button'
import BodyText from '../../app/components/BodyText'
import {
  checkoutDeletePendingSelector,
  currentCheckoutOutletNameSelector,
} from '../../checkout/store/checkoutSelectors'
import { preOrderExistWarningModalVisibleSelector } from '../store/preOrderSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  preOrderClearCheckout,
  setPreOrderExistWarningModalVisible,
} from '../store/preOrderSlice'
import localStorageUtils from '../../app/utils/localStorageUtils'

import styles from './LeavePreOrderWarningModal.module.scss'

type LeavePreOrderWarningModalProps = {
  onStartNewOrder: () => void
  onClose: () => void
}

export default function LeavePreOrderWarningModal({
  onStartNewOrder,
  onClose,
}: LeavePreOrderWarningModalProps) {
  const { t } = useTranslation('preOrder')
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(preOrderExistWarningModalVisibleSelector)
  const outletName = useAppSelector(currentCheckoutOutletNameSelector)
  const deletePending = useAppSelector(checkoutDeletePendingSelector)

  function handleOnClose() {
    dispatch(setPreOrderExistWarningModalVisible(false))
    onClose()
  }

  async function handleOnPressStartNewOrder() {
    batch(() => {
      dispatch(preOrderClearCheckout())
      dispatch(setPreOrderExistWarningModalVisible(false))
    })
    localStorageUtils.removeItem('cartMeta')
    localStorageUtils.removeItem('preOrderUserToken')
    onStartNewOrder()
  }

  return (
    <Modal
      title={t('leavePreOrderWarningModal.title')}
      isOpen={isOpen}
      onClose={handleOnClose}
      mobileModalMode="bottom-sheet"
      className={styles.modal}
    >
      <div className={styles.modalBody}>
        <BodyText color="body-1">
          {t('leavePreOrderWarningModal.message', { outletName })}
        </BodyText>
      </div>
      <div className={styles.modalFooter}>
        <Button variant="secondary" onClick={handleOnClose}>
          {t('leavePreOrderWarningModal.keepExistingOrder')}
        </Button>
        <Button onClick={handleOnPressStartNewOrder} disabled={deletePending}>
          {t('leavePreOrderWarningModal.startNewOrder')}
        </Button>
      </div>
    </Modal>
  )
}
