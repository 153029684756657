import clsx from 'clsx'

import Skeleton from '../../../app/components/Skeleton'
import PreOrderTotalSkeleton from '../skeletons/PreOrderTotalSkeleton'

import PreOrderAttendeeListSkeleton from './PreOrderAttendeeListSkeleton'
import PreOrderItemListSkeleton from './PreOrderItemListSkeleton'
import styles from './PreOrderAllDetailsSkeleton.module.scss'

interface PreOrderAllDetailsSkeletonProps {
  isShareable?: boolean
}

export default function PreOrderAllDetailsSkeleton({
  isShareable = false,
}: PreOrderAllDetailsSkeletonProps) {
  return (
    <div>
      <div className={clsx(styles.detailsBody, styles.content)}>
        <div className={styles.left}>
          <div className={styles.orderSummary}>
            {Array.from(Array(5).keys()).map(index => (
              <div key={index} className={styles.iconDataField}>
                <Skeleton className={styles.fieldIcon} />
                <div className={styles.fieldContent}>
                  <Skeleton className={styles.fieldLabel} />
                  <Skeleton className={styles.fieldValue} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          {isShareable ? (
            <PreOrderAttendeeListSkeleton />
          ) : (
            <PreOrderItemListSkeleton />
          )}
        </div>
      </div>
      <div className={clsx(styles.content, styles.footer)}>
        <div className={clsx(styles.left, styles.footerActions)}>
          {isShareable && <Skeleton className={styles.footerButton} />}
          <Skeleton className={styles.footerButton} />
        </div>
        <div className={styles.right}>
          <PreOrderTotalSkeleton />
        </div>
      </div>
    </div>
  )
}
