import TransText from 'next-translate/TransText'
import useTranslation from 'next-translate/useTranslation'
import MoreOptionsIcon from '@ancon/wildcat-ui/shared/icons/ellipsis-vertical.svg'
import BackIcon from '@ancon/wildcat-ui/shared/icons/arrow-back-long.svg'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import { useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import { useMemo } from 'react'

import { DropdownButtonProps } from '../../../app/components/dropdown/DropdownButton'
import Dropdown from '../../../app/components/dropdown/Dropdown'
import Button from '../../../app/components/Button'
import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  preOrderGoBackNewOrderStep,
  preOrderGoNextNewOrderStep,
  resetNewOrderDetails,
  setNewPreOrderEditModeStep,
  setNewPreOrderModalVisible,
} from '../../store/preOrderSlice'
import { NewOrderStep } from '../../types'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderIsNewOrderEditModeSelector,
  preOrderNewOrderStepSelector,
} from '../../store/preOrderSelectors'
import { outletsSelectedOutletListItemNameSelector } from '../../../outlets/store/outletsSelectors'

import styles from './NewOrderHeader.module.scss'

enum NewOrderHeaderAction {
  Reset = 'Reset',
  Close = 'Close',
}

function HeaderActionsDropdownButton({
  className,
  onToggle,
}: DropdownButtonProps) {
  return (
    <Button
      variant="secondary"
      onClick={onToggle}
      className={className}
      outlined
    >
      <MoreOptionsIcon />
    </Button>
  )
}

interface NewOrderHeaderProps {
  title?: string
  showOutlet?: boolean
  inModal?: boolean
  isMobile?: boolean
  className?: string
}

export default function NewOrderHeader({
  title,
  showOutlet = false,
  inModal = false,
  isMobile = false,
  className,
}: NewOrderHeaderProps) {
  const { t } = useTranslation('preOrder')

  const formContext = useFormContext()

  const newOrderStep = useAppSelector(preOrderNewOrderStepSelector)

  const isEditMode = useAppSelector(preOrderIsNewOrderEditModeSelector)

  const outletName = useAppSelector(outletsSelectedOutletListItemNameSelector)

  const dispatch = useAppDispatch()

  const canGoBack = useMemo(() => {
    if (inModal) {
      return newOrderStep > NewOrderStep.OrderConfiguration && !isEditMode
    }

    return isEditMode || newOrderStep > NewOrderStep.OrderConfiguration
  }, [inModal, isEditMode, newOrderStep])

  const moreActions = useMemo(() => {
    const actions = [
      {
        label: t('newOrder.close'),
        value: NewOrderHeaderAction.Close,
      },
    ]

    if (!isEditMode) {
      actions.unshift({
        label: t('newOrder.reset'),
        value: NewOrderHeaderAction.Reset,
      })
    }

    return actions
  }, [isEditMode, t])

  function handleHeaderAction(action: string) {
    switch (action as NewOrderHeaderAction) {
      case NewOrderHeaderAction.Reset:
        dispatch(
          preOrderGoNextNewOrderStep({
            step: NewOrderStep.OrderConfiguration,
            isMobile,
          }),
        )
        dispatch(resetNewOrderDetails())
        formContext?.reset()
        break
      case NewOrderHeaderAction.Close:
        dispatch(
          preOrderGoNextNewOrderStep({ step: NewOrderStep.None, isMobile }),
        )
        dispatch(resetNewOrderDetails())
        break
      default:
        break
    }
  }

  function handleGoBack() {
    dispatch(preOrderGoBackNewOrderStep({ isMobile }))
  }

  function handleClose() {
    if (isEditMode) {
      dispatch(setNewPreOrderModalVisible(false))
      dispatch(setNewPreOrderEditModeStep(NewOrderStep.None))
    } else {
      handleHeaderAction(NewOrderHeaderAction.Close)
    }
  }

  return (
    <div className={clsx(styles.headerContainer, className)}>
      <div className={styles.headerLeft}>
        {canGoBack && (
          <Button
            variant="secondary"
            outlined
            className={clsx(styles.headerButton, styles.headerBackButton)}
            onClick={handleGoBack}
          >
            <BackIcon />
          </Button>
        )}
        <div className={styles.headerTitle}>
          <HeadingText as="h3" color="heading-1">
            {title ?? t('newOrder.title')}
          </HeadingText>
          {showOutlet && !!outletName && (
            <BodyText className={styles.subHeader} color="body-2">
              <TransText
                text={t('newOrder.fromOutlet', { outletName })}
                components={{ highlight: <span /> }}
              />
            </BodyText>
          )}
        </div>
      </div>
      {inModal ? (
        <Button
          variant="secondary"
          outlined
          className={clsx(styles.headerButton, styles.headerCloseButton)}
          onClick={handleClose}
        >
          <CloseIcon />
        </Button>
      ) : (
        <Dropdown
          buttonClassName={styles.headerMoreActionsButton}
          CustomDropdownButton={HeaderActionsDropdownButton}
          popupPlacement="top-end"
          onClickOption={handleHeaderAction}
          popupClassName={styles.headerActionsPopup}
          options={moreActions}
        />
      )}
    </div>
  )
}
