import useTranslation from 'next-translate/useTranslation'
import getTotalCheckoutItemsQuantity from '@ancon/wildcat-utils/checkout/getTotalCheckoutItemsQuantity'

import HeadingText from '../../../app/components/HeadingText'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { selectedPreOrderItemsSelector } from '../../store/preOrderSelectors'
import EmptyPlaceholder from '../../../app/components/emptyPlaceholder/EmptyPlaceholder'

import PreOrderItemList from './PreOrderItemList'
import styles from './IndividualPreOrderItemList.module.scss'

export default function IndividualPreOrderItemList() {
  const { t } = useTranslation('preOrder')

  const orderItems = useAppSelector(selectedPreOrderItemsSelector)

  const totalItemsQty = getTotalCheckoutItemsQuantity(orderItems)

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeadingText as="h3" color="heading-1">
          {t('orderDetails')}
        </HeadingText>
        {totalItemsQty > 0 && (
          <HeadingText as="h3" color="heading-1">
            &nbsp; &middot; &nbsp;{totalItemsQty}
          </HeadingText>
        )}
      </div>
      <PreOrderItemList
        items={orderItems}
        emptyListPlaceholder={
          <EmptyPlaceholder
            className={styles.emptyItemList}
            title={t('emptyOrderItems.title')}
            message={t('emptyOrderItems.message')}
          />
        }
      />
    </div>
  )
}
