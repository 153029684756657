import { useEffect } from 'react'

type UsePageBottomProps = {
  offset?: number
  onReachedBottom: () => void
}

export default function usePageBottom({
  offset = 10,
  onReachedBottom,
}: UsePageBottomProps) {
  useEffect(() => {
    function handleScroll() {
      const { offsetHeight, scrollTop } = document.documentElement
      const { innerHeight } = window

      const hasReachedBottom =
        offsetHeight - (innerHeight + scrollTop) <= offset

      if (hasReachedBottom) {
        onReachedBottom()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [offset, onReachedBottom])
}
