import useAppSelector from '../../../../store/hooks/useAppSelector'
import { preOrderNewOrderStepSelector } from '../../store/preOrderSelectors'
import { NewOrderStep } from '../../types'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  setNewPreOrderEditModeStep,
  setNewPreOrderModalVisible,
} from '../../store/preOrderSlice'

import NewGroupOrderHostPreview from './NewGroupOrderHostPreview'
import NewGroupOrderSummary from './NewGroupOrderSummary'
import GroupOrderRequestReceived from './GroupOrderRequestReceived'
import GroupOrderSharedLink from './GroupOrderSharedLink'
import styles from './NewOrderMobile.module.scss'

export default function NewOrderMobile() {
  const dispatch = useAppDispatch()
  const newOrderStep = useAppSelector(preOrderNewOrderStepSelector)

  function handleEditNewGroupOrder(step: NewOrderStep) {
    dispatch(setNewPreOrderModalVisible(true))
    dispatch(setNewPreOrderEditModeStep(step))
  }

  function renderPlaceNewOrderStep() {
    switch (newOrderStep) {
      case NewOrderStep.HostPreview:
        return <NewGroupOrderHostPreview isMobile />

      case NewOrderStep.Summary:
        return (
          <NewGroupOrderSummary isMobile onEdit={handleEditNewGroupOrder} />
        )

      case NewOrderStep.GroupOrderReceived:
        return <GroupOrderRequestReceived />

      case NewOrderStep.ShareGroupOrderLink:
        return <GroupOrderSharedLink />

      default:
        return null
    }
  }

  return <div className={styles.container}>{renderPlaceNewOrderStep()}</div>
}
