import useTranslation from 'next-translate/useTranslation'
import EmptyBasketIcon from '@ancon/wildcat-ui/shared/icons/basket-empty-outline.svg'
import moment from 'moment'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderCalendarOrderByIdSelector,
  preOrderCalendarOrdersForSelectedDateSelector,
  preOrderCalendarScheduledOrdersLoadingSelector,
  preOrderCalendarSelectedDateSelector,
} from '../../store/preOrderSelectors'
import UpcomingOrderItem from '../upcomingOrders/UpcomingOrderItem'
import UpcomingOrdersSkeleton from '../upcomingOrders/UpcomingOrdersSkeleton'
import useAppStore from '../../../../store/hooks/useAppStore'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { preOrderSetSelectedPreOrder } from '../../store/preOrderSlice'

import styles from './ScheduledOrders.module.scss'

export default function ScheduledOrders() {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const calendarSelectedDate = useAppSelector(
    preOrderCalendarSelectedDateSelector,
  )

  const isOrdersLoading = useAppSelector(
    preOrderCalendarScheduledOrdersLoadingSelector,
  )

  const scheduledOrders = useAppSelector(
    preOrderCalendarOrdersForSelectedDateSelector,
  )

  function handleClickScheduledOrder(id: string) {
    const preOrderListItem = preOrderCalendarOrderByIdSelector(
      store.getState(),
      id,
    )
    if (preOrderListItem) {
      dispatch(preOrderSetSelectedPreOrder(preOrderListItem))
    }
  }

  function getFormattedCalendarDate(short: boolean = false) {
    return moment(calendarSelectedDate).calendar({
      sameDay: `[${t('common:today').toLowerCase()}]`,
      nextDay: `[${t('common:tomorrow').toLowerCase()}]`,
      lastDay: `[${t('common:yesterday').toLowerCase()}]`,
      nextWeek: 'dddd',
      lastWeek: `[${t('common:last')}] dddd`,
      sameElse: short ? 'll' : 'LL',
    })
  }

  return (
    <div className={styles.container}>
      {(isOrdersLoading || !!scheduledOrders.length) && (
        <div className={styles.header}>
          <HeadingText as="h3">{getFormattedCalendarDate()}</HeadingText>
          {!!scheduledOrders.length && (
            <HeadingText as="h3">
              &nbsp; &middot; &nbsp;
              {scheduledOrders.length}
            </HeadingText>
          )}
        </div>
      )}
      {isOrdersLoading && <UpcomingOrdersSkeleton showIcon />}
      {!isOrdersLoading &&
        (scheduledOrders.length ? (
          <div className={styles.orderList}>
            {scheduledOrders.map(order => (
              <UpcomingOrderItem
                key={order.id}
                orderCheckout={order}
                onClick={handleClickScheduledOrder}
                showIcon
              />
            ))}
          </div>
        ) : (
          <div className={styles.emptyPlaceholder}>
            <EmptyBasketIcon />
            <BodyText color="body-2">
              {t('orderCalendar.noScheduledOrders', {
                date: getFormattedCalendarDate(true),
              })}
            </BodyText>
          </div>
        ))}
    </div>
  )
}
