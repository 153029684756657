import { useEffect, useMemo, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import ArrowForwardIcon from '@ancon/wildcat-ui/shared/icons/arrow-forward-long.svg'
import {
  CheckoutDetails,
  CheckoutOrderType,
  PreOrderCheckoutDetails,
  PreOrderStatus,
} from '@ancon/wildcat-types'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import omit from 'lodash/omit'

import Modal from '../../../app/components/modal/Modal'
import Button from '../../../app/components/Button'
import {
  preOrderLinkOutletURLSlugSelector,
  preOrderSelectedListItemHostAttendeeIdSelector,
  preOrderSelectedListItemIsSharableSelector,
  preOrderSelectedOrderDetailsSelector,
  preOrderSelectedOrderFetchPendingSelector,
  preOrderSelectedPreOrderIdSelector,
  selectedPreOrderOrderFormatSelector,
  selectedPreOrderOrderTypeSelector,
  selectedPreOrderOutletIdSelector,
  selectedPreOrderStatusSelector,
} from '../../store/preOrderSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderSetSelectedPreOrder,
  setPreOrderUser,
} from '../../store/preOrderSlice'
import { fetchCustomerPreOrderDetails } from '../../store/preOrderThunks'
import PreOrderTotal from '../PreOrderTotal'
import OrderTotal from '../../../checkout/components/orderTotal/OrderTotal'
import PreOrderAllDetailsSkeleton from '../skeletons/PreOrderAllDetailsSkeleton'
import localStorageUtils from '../../../app/utils/localStorageUtils'
import { LocalStorageCartMeta } from '../../../checkout/types'
import useAppStore from '../../../../store/hooks/useAppStore'
import { PreOrderUser } from '../../types'
import {
  clientContextCustomerEmailSelector,
  clientContextCustomerNameSelector,
} from '../../../clientContext/store/clientContextSelectors'
import storedPreOrderUserToken from '../../utils/storedPreOrderUserToken'
import { AppRoutes } from '../../../app/constants'
import getPreOrderPageURL from '../../utils/getPreOrderPageURL'
import useIsRouteChanging from '../../../app/hooks/useIsRouteChanging'
import isPreOrderStatusAvailable from '../../utils/isPreOrderStatusAvaialable'

import IndividualPreOrderItemList from './IndividualPreOrderItemList'
import PreOrderDetailsModalHeader from './PreOrderDetailsModalHeader'
import PreOrderConfigurationSummary from './PreOrderConfigurationSummary'
import PreOrderAttendeesSearchList from './PreOrderAttendeesSearchList'
import styles from './PreOrderDetailsModal.module.scss'

enum ModalAction {
  AddItems,
  PreOrderManage,
  ViewOrderStatus,
}

export default function PreOrderDetailsModal() {
  const { t } = useTranslation('preOrder')

  const router = useRouter()

  const store = useAppStore()

  const dispatch = useAppDispatch()

  const selectedPreOrderId = useAppSelector(preOrderSelectedPreOrderIdSelector)

  const isOrderDetailsFetchPending = useAppSelector(
    preOrderSelectedOrderFetchPendingSelector,
  )
  const preOrderDetails = useAppSelector(preOrderSelectedOrderDetailsSelector)
  const isSharablePreOrder = useAppSelector(
    preOrderSelectedListItemIsSharableSelector,
  )!
  const preOrderStatus = useAppSelector(selectedPreOrderStatusSelector)
  const orderType = useAppSelector(selectedPreOrderOrderTypeSelector)

  const clickedAction = useRef<ModalAction | null>(null)

  // Whether host can manage pre-order or add items to it
  const canManage =
    preOrderStatus === PreOrderStatus.Activated ||
    preOrderStatus === PreOrderStatus.Started

  // Whether order status page is available for pre-order
  const canViewOrderStatus = useMemo(() => {
    if (isSharablePreOrder) {
      return isPreOrderStatusAvailable(preOrderStatus)
    }
    return orderType === CheckoutOrderType.PreOrder
  }, [isSharablePreOrder, orderType, preOrderStatus])

  const isRouteChanging = useIsRouteChanging(() => {
    dispatch(preOrderSetSelectedPreOrder(null))
  })

  useEffect(() => {
    if (selectedPreOrderId) {
      dispatch(fetchCustomerPreOrderDetails())
    }
  }, [dispatch, selectedPreOrderId])

  function handleOnClose() {
    dispatch(preOrderSetSelectedPreOrder(null))
  }

  function storePreOrderCheckoutMetaIfNeeded(skipCartMeta?: boolean) {
    const outletId = selectedPreOrderOutletIdSelector(store.getState())!
    const hostAttendeeId = preOrderSelectedListItemHostAttendeeIdSelector(
      store.getState(),
    )!
    const hostEmail = clientContextCustomerEmailSelector(store.getState())!
    const hostName = clientContextCustomerNameSelector(store.getState())

    const storedCart =
      localStorageUtils.getItem<LocalStorageCartMeta>('cartMeta')
    const storedAttendee = storedPreOrderUserToken.getUser()

    if (skipCartMeta) {
      localStorageUtils.removeItem('cartMeta')
    } else if (
      !storedCart ||
      !storedCart.isPreOrderCheckout ||
      storedCart.checkoutId !== selectedPreOrderId
    ) {
      // Create cart in local storage
      localStorageUtils.setItem<LocalStorageCartMeta>('cartMeta', {
        outletId,
        checkoutId: selectedPreOrderId!,
        isPreOrderCheckout: true,
      })
    }

    if (!storedAttendee || storedAttendee.id !== hostAttendeeId) {
      // Create pre-order user token associated with selected pre-order
      const preOrderUser: PreOrderUser = {
        id: hostAttendeeId,
        email: hostEmail,
        name: hostName,
        isHost: true,
      }
      storedPreOrderUserToken.create(preOrderUser)
      dispatch(setPreOrderUser(preOrderUser))
    }
  }

  function handleAddItems() {
    clickedAction.current = ModalAction.AddItems
    storePreOrderCheckoutMetaIfNeeded()

    const urlSlug = preOrderLinkOutletURLSlugSelector(store.getState())
    const orderFormat = selectedPreOrderOrderFormatSelector(store.getState())

    if (urlSlug && orderFormat) {
      router.push(getPreOrderPageURL(urlSlug, orderFormat))
    }
  }

  function handleManagePreOrder() {
    clickedAction.current = ModalAction.PreOrderManage
    storePreOrderCheckoutMetaIfNeeded()
    router.push(AppRoutes.PreOrderManage)
  }

  function handleViewOrderStatus() {
    clickedAction.current = ModalAction.ViewOrderStatus
    storePreOrderCheckoutMetaIfNeeded(true)
    const outletId = selectedPreOrderOutletIdSelector(store.getState())!

    const orderStatusPath = isSharablePreOrder
      ? AppRoutes.PreOrderStatus
      : AppRoutes.OrderStatus

    const checkoutId = selectedPreOrderId!

    router.push(`${orderStatusPath}/${outletId}/${checkoutId}`)
  }

  return (
    <Modal
      CustomModalHeader={PreOrderDetailsModalHeader}
      isOpen={!!selectedPreOrderId}
      onClose={handleOnClose}
      mobileModalMode="bottom-sheet"
      contentClassName={styles.modalContent}
    >
      {isOrderDetailsFetchPending || !preOrderDetails ? (
        <PreOrderAllDetailsSkeleton isShareable={isSharablePreOrder} />
      ) : (
        <>
          <div className={clsx(styles.modalBody, styles.content)}>
            <div className={styles.left}>
              <PreOrderConfigurationSummary />
            </div>
            <div className={styles.right}>
              {isSharablePreOrder ? (
                <PreOrderAttendeesSearchList />
              ) : (
                <IndividualPreOrderItemList />
              )}
            </div>
          </div>
          <div className={clsx(styles.content, styles.modalFooter)}>
            <div className={clsx(styles.left, styles.modalFooterActions)}>
              {canManage && (
                <>
                  <Button
                    className={clsx({
                      [styles.secondary]: isSharablePreOrder,
                    })}
                    variant={isSharablePreOrder ? 'secondary' : 'primary'}
                    size="large"
                    onClick={handleAddItems}
                    loading={
                      isRouteChanging &&
                      clickedAction.current === ModalAction.AddItems
                    }
                  >
                    {t('addItems')}
                  </Button>
                  {isSharablePreOrder && (
                    <Button
                      size="large"
                      onClick={handleManagePreOrder}
                      loading={
                        isRouteChanging &&
                        clickedAction.current === ModalAction.PreOrderManage
                      }
                    >
                      {t('manageOrder')}
                    </Button>
                  )}
                </>
              )}
              {canViewOrderStatus && (
                <Button
                  className={styles.viewStatusButton}
                  size="large"
                  onClick={handleViewOrderStatus}
                  loading={
                    isRouteChanging &&
                    clickedAction.current === ModalAction.ViewOrderStatus
                  }
                >
                  {t('goToStatusPage')}
                  <ArrowForwardIcon />
                </Button>
              )}
            </div>
            <div className={styles.right}>
              {isSharablePreOrder ? (
                <PreOrderTotal
                  className={styles.orderTotal}
                  checkout={
                    (canViewOrderStatus
                      ? omit(preOrderDetails, 'minimumOrderDifferenceAmount')
                      : preOrderDetails) as PreOrderCheckoutDetails
                  }
                  separateTotal
                  showTotalItemsQty
                />
              ) : (
                <OrderTotal
                  className={styles.orderTotal}
                  orderSummaryTotals={
                    (canViewOrderStatus
                      ? omit(preOrderDetails, 'minimumOrderDifferenceAmount')
                      : preOrderDetails) as CheckoutDetails
                  }
                  separateTotal
                />
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}
