import { useCallback, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import EmptyBasketIcon from '@ancon/wildcat-ui/shared/icons/basket-empty-outline.svg'
import clsx from 'clsx'
import noop from 'lodash/noop'
import { useRouter } from 'next/router'
import { usePrevious } from '@ancon/wildcat-utils/shared/hooks'

import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderAllUpcomingOrdersSelector,
  preOrderHasMoreUpcomingOrdersSelector,
  preOrderIsNewOrderModeSelector,
  preOrderUpcomingOrderByIdSelector,
  preOrderUpcomingOrderListOffsetSelector,
  preOrderUpcomingOrderListTotalCountSelector,
  preOrderUpcomingOrdersFetchPendingSelector,
} from '../../store/preOrderSelectors'
import { fetchUpcomingOrderList } from '../../store/preOrderThunks'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import PreOrderDetailsModal from '../preOrderDetailsModal/PreOrderDetailsModal'
import useAppStore from '../../../../store/hooks/useAppStore'
import { preOrderSetSelectedPreOrder } from '../../store/preOrderSlice'
import { UpcomingOrdersPagination } from '../../constants'
import { AppRoutes } from '../../../app/constants'
import { MyOrdersPageQuery, OrdersTab } from '../../../orders/types'
import { clientContextCurrentUserCompanyIdSelector } from '../../../clientContext/store/clientContextSelectors'

import UpcomingOrderItem from './UpcomingOrderItem'
import UpcomingOrdersSkeleton from './UpcomingOrdersSkeleton'
import styles from './UpcomingOrders.module.scss'

export default function UpcomingOrders() {
  const { t } = useTranslation('preOrder')

  const router = useRouter()

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const [isInitialized, setInitialized] = useState<boolean>(false)

  const isNewOrderMode = useAppSelector(preOrderIsNewOrderModeSelector)

  const totalCount = useAppSelector(preOrderUpcomingOrderListTotalCountSelector)

  const upcomingOrders = useAppSelector(preOrderAllUpcomingOrdersSelector)

  const isFetchPending = useAppSelector(
    preOrderUpcomingOrdersFetchPendingSelector,
  )

  const hasMore = useAppSelector(preOrderHasMoreUpcomingOrdersSelector)

  const companyId = useAppSelector(clientContextCurrentUserCompanyIdSelector)

  const prevCompanyId = usePrevious(companyId)

  const initUpcomingOrders = useCallback(() => {
    dispatch(
      fetchUpcomingOrderList({
        offset: 0,
        limit: UpcomingOrdersPagination.InitialLimit,
      }),
    )
      .unwrap()
      .catch(noop)
      .finally(() => setInitialized(true))
  }, [dispatch])

  useEffect(() => {
    // Initialize upcoming orders on mount
    initUpcomingOrders()
  }, [initUpcomingOrders])

  useEffect(() => {
    const didCompanyChange =
      prevCompanyId && companyId && prevCompanyId !== companyId
    // If company changed, re-initialize upcoming orders
    if (didCompanyChange) {
      setInitialized(false)
      initUpcomingOrders()
    }
  }, [companyId, initUpcomingOrders, prevCompanyId])

  const handleLoadMoreClick = useCallback(() => {
    const offset = preOrderUpcomingOrderListOffsetSelector(store.getState())

    dispatch(
      fetchUpcomingOrderList({
        offset,
        limit: UpcomingOrdersPagination.LoadMoreLimit,
      }),
    )
  }, [dispatch, store])

  function handleClickPastOrders() {
    const query: MyOrdersPageQuery = {
      tab: OrdersTab.Past,
    }

    router.push({
      pathname: AppRoutes.Orders,
      query,
    })
  }

  function handleClickUpcomingOrder(id: string) {
    const preOrderListItem = preOrderUpcomingOrderByIdSelector(
      store.getState(),
      id,
    )
    if (preOrderListItem) {
      dispatch(preOrderSetSelectedPreOrder(preOrderListItem))
    }
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.disabled]: isNewOrderMode,
      })}
    >
      <div className={styles.header}>
        <div className={styles.row}>
          <BodyText as="h3" color="body-2">
            {t('upcomingOrders.title')}
          </BodyText>
          {totalCount > 0 && (
            <BodyText as="h3" color="body-2">
              &nbsp; &middot; &nbsp;
              {totalCount}
            </BodyText>
          )}
        </div>
        {!!upcomingOrders.length && (
          <Button
            variant="secondary"
            className={styles.pastOrdersButton}
            disabled={upcomingOrders.length === 0}
            onClick={handleClickPastOrders}
          >
            {t('upcomingOrders.pastOrders')}
          </Button>
        )}
      </div>
      {!isInitialized && <UpcomingOrdersSkeleton showOrderDate />}
      {isInitialized &&
        (upcomingOrders.length > 0 ? (
          <div className={styles.orderList}>
            {upcomingOrders.map(order => (
              <UpcomingOrderItem
                key={order.id}
                orderCheckout={order}
                showOrderDate
                onClick={handleClickUpcomingOrder}
              />
            ))}
          </div>
        ) : (
          <div className={styles.emptyPlaceholder}>
            <EmptyBasketIcon />
            <BodyText>{t('upcomingOrders.noOrdersAvailable')}</BodyText>
          </div>
        ))}
      <div className={styles.footerSection}>
        {isInitialized && hasMore && (
          <Button
            className={styles.loadMoreButton}
            variant="secondary"
            loading={isFetchPending}
            onClick={handleLoadMoreClick}
            outlined
          >
            {t('upcomingOrders.loadMore')}
          </Button>
        )}
      </div>
      <PreOrderDetailsModal />
    </div>
  )
}
