import { CheckoutOrderType } from '@ancon/wildcat-types'
import isGroupPreOrder from '@ancon/wildcat-utils/preOrder/isGroupPreOrder'

import { AppRoutes, DefaultLanguage } from '../../app/constants'

export default function createPreOrderSharableLink(
  preOrderId: string,
  orderType: CheckoutOrderType,
  lang: string = DefaultLanguage,
) {
  const languagePath = lang === DefaultLanguage ? '' : `/${lang}`
  const preOrderPath = isGroupPreOrder({ orderType })
    ? AppRoutes.GroupOrder
    : AppRoutes.PreOrder

  return `${window.location.origin}${languagePath}${preOrderPath}/${preOrderId}`
}
