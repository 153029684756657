import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import useTranslation from 'next-translate/useTranslation'
import { PreOrderStatus } from '@ancon/wildcat-types'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import { ModalHeaderProps } from '../../../app/components/modal/ModalHeader'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderSelectedListItemIsSharableSelector,
  preOrderSelectedListItemOrderNameSelector,
  preOrderSelectedListItemOrderTypeSelector,
  preOrderSelectedListItemTicketNumberSelector,
  preOrderSelectedPreOrderIdSelector,
  selectedPreOrderIsGroupOrderSelector,
  selectedPreOrderStatusSelector,
} from '../../store/preOrderSelectors'
import useClipboard from '../../../app/hooks/useClipboard'
import useAppStore from '../../../../store/hooks/useAppStore'
import createPreOrderSharableLink from '../../utils/createPreOrderSharableLink'
import GroupOrderApprovalStatusTag from '../GroupOrderApprovalStatusTag'

import styles from './PreOrderDetailsModalHeader.module.scss'

export default function PreOrderDetailsModalHeader({
  onClose,
}: ModalHeaderProps) {
  const { t, lang } = useTranslation('preOrder')

  const store = useAppStore()

  const clipboard = useClipboard()

  const preOrderName = useAppSelector(preOrderSelectedListItemOrderNameSelector)
  const ticketNumber = useAppSelector(
    preOrderSelectedListItemTicketNumberSelector,
  )
  const orderType = useAppSelector(preOrderSelectedListItemOrderTypeSelector)!
  const isPreOrderSharable = useAppSelector(
    preOrderSelectedListItemIsSharableSelector,
  )
  const isGroupOrder = useAppSelector(selectedPreOrderIsGroupOrderSelector)
  const preOrderStatus = useAppSelector(selectedPreOrderStatusSelector)

  const isActivatedPreOrder = preOrderStatus >= PreOrderStatus.Activated

  async function handleCopyPreOrderLink() {
    const preOrderId = preOrderSelectedPreOrderIdSelector(store.getState())

    if (preOrderId) {
      const sharedLink = createPreOrderSharableLink(preOrderId, orderType, lang)
      await clipboard.copyText(sharedLink)
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <HeadingText as="h2" color="heading-1">
          {preOrderName ??
            t('upcomingOrders.orderNumber', { orderNumber: ticketNumber })}
        </HeadingText>
        {isGroupOrder && (
          <GroupOrderApprovalStatusTag status={preOrderStatus} />
        )}
      </div>
      <div className={styles.headerActions}>
        {isPreOrderSharable && isActivatedPreOrder && (
          <Button
            variant="secondary"
            className={styles.copyButton}
            onClick={handleCopyPreOrderLink}
          >
            <BodyText as="span">
              {clipboard.isCopied ? t('copied') : t('copyLink')}
            </BodyText>
          </Button>
        )}
        {onClose && (
          <div
            className={styles.closeButton}
            onClick={onClose}
            role="presentation"
          >
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  )
}
