import Skeleton from '../../../../features/app/components/Skeleton'

import styles from './PreOrderAttendeeListSkeleton.module.scss'

export default function PreOrderAttendeeListSkeleton() {
  return (
    <div>
      <div className={styles.header}>
        <Skeleton className={styles.title} />
        <div className={styles.searchBox} />
      </div>
      <div className={styles.attendeeList}>
        {Array.from(Array(2).keys()).map(index => (
          <div key={index} className={styles.attendeeItem}>
            <Skeleton className={styles.dropdownIcon} />
            <div className={styles.attendeeDetails}>
              <Skeleton className={styles.attendeeName} />
              <Skeleton className={styles.itemsCount} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
