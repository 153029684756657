import Skeleton from '@ancon/wildcat-ui/web/orderweb/Skeleton'

import styles from './GreetingUserSkeleton.module.scss'

export default function GreetingUserSkeleton() {
  return (
    <div>
      <Skeleton className={styles.headerText} />
      <div className={styles.row}>
        <Skeleton className={styles.icon} />
        <Skeleton className={styles.text} />
      </div>
    </div>
  )
}
