import moment from 'moment'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import { clientContextCustomerEmailSelector } from '../../../clientContext/store/clientContextSelectors'
import {
  selectedPreOrderOrderFormatSelector,
  selectedPreOrderOrderMethodSelector,
  selectedPreOrderOutletNameSelector,
  selectedPreOrderServiceTimeSelector,
} from '../../store/preOrderSelectors'
import PreOrderConfigurationOverview, {
  PreOrderConfigField,
} from '../PreOrderConfigurationOverview'

import styles from './PreOrderConfigurationSummary.module.scss'

export default function PreOrderConfigurationSummary() {
  const outletName = useAppSelector(selectedPreOrderOutletNameSelector)!
  const serviceTime = useAppSelector(selectedPreOrderServiceTimeSelector)!
  const orderFormat = useAppSelector(selectedPreOrderOrderFormatSelector)!
  const orderMethod = useAppSelector(selectedPreOrderOrderMethodSelector)
  const customerEmail = useAppSelector(clientContextCustomerEmailSelector)

  return (
    <div className={styles.container}>
      <PreOrderConfigurationOverview
        dataMapper={{
          [PreOrderConfigField.Restaurant]: outletName,
          [PreOrderConfigField.OrderDate]: serviceTime?.time,
          [PreOrderConfigField.OrderTime]: moment(serviceTime?.time).valueOf(),
          [PreOrderConfigField.OrderType]: orderFormat,
          [PreOrderConfigField.OrderMethod]: orderMethod,
          [PreOrderConfigField.Customer]: customerEmail,
        }}
      />
    </div>
  )
}
