import React, { PropsWithChildren } from 'react'
import CheckIcon from '@ancon/wildcat-ui/shared/icons/check-circle-fill.svg'

import BodyText from '../../app/components/BodyText'

import styles from './PreOrderIconDataField.module.scss'

type PreOrderIconDataFieldProps = PropsWithChildren<{
  icon: React.ReactNode
  label: string
  placeholder?: string
  highlighted?: boolean
  showCompletedIndicator?: boolean
}>

export default function PreOrderIconDataField({
  icon,
  label,
  children: value,
  placeholder,
  highlighted = false,
  showCompletedIndicator = false,
}: PreOrderIconDataFieldProps) {
  return (
    <div className={styles.fieldContainer}>
      <div className={styles.fieldLeftContent}>
        <div className={styles.fieldIcon}>{icon}</div>
        <div className={styles.fieldData}>
          {value && (
            <BodyText as="label" color="body-2" fontSize="1.2rem">
              {label}
            </BodyText>
          )}
          <BodyText as="span">{value || placeholder || ''}</BodyText>
        </div>
      </div>
      {showCompletedIndicator && value && (
        <CheckIcon className={styles.fieldCompletedIndicator} />
      )}
      {highlighted && !value && <div className={styles.highlighted} />}
    </div>
  )
}
