import { OutletListItem } from '@ancon/wildcat-types'
import getPartitionedOutletsList from '@ancon/wildcat-utils/outlet/getPartitionedOutletsList'
import { OutletListSection } from '@ancon/wildcat-utils/outlet/types'
import { Translate } from 'next-translate'

import { OutletListingSection } from '../types'

import isOutletsGroupByStatus from './isOutletsGroupByStatus'

const TitleMap: {
  [key: string]: string
} = {
  [OutletListSection.OrderNow]: 'outletSections.orderNow',
  [OutletListSection.PreOrder]: 'outletSections.preOrder',
  [OutletListSection.Closed]: 'outletSections.closed',
}

export default function getOutletSectionList(
  outlets: OutletListItem[],
  t: Translate,
) {
  const isGroupByStatus = isOutletsGroupByStatus()

  if (isGroupByStatus) {
    const partitionedOutlets = getPartitionedOutletsList(outlets)

    return Object.keys(partitionedOutlets).reduce<OutletListingSection[]>(
      (acc, key) => {
        const data = partitionedOutlets[key]

        if (data.length) {
          acc.push({
            title: t(TitleMap[key]),
            data,
          })
        }
        return acc
      },
      [],
    )
  }

  return [
    {
      title: '',
      data: outlets,
    },
  ]
}
