import clsx from 'clsx'
import { useCallback, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { preOrderNewOrderStepSelector } from '../../preOrder/store/preOrderSelectors'
import useAppSelector from '../../../store/hooks/useAppSelector'
import Button from '../../app/components/Button'
import PlaceNewOrder from '../../preOrder/components/PlaceNewOrder'
import OrderCalendar from '../../preOrder/components/orderCalendar/OrderCalendar'
import UpcomingOrders from '../../preOrder/components/upcomingOrders/UpcomingOrders'
import SelectedRestaurant from '../../outlets/components/selectedRestaurant/SelectedRestaurant'
import NewOrderMobile from '../../preOrder/components/newOrder/NewOrderMobile'
import NewOrderModal from '../../preOrder/components/newOrder/NewOrderModal'
import { NewOrderStep } from '../../preOrder/types'

import styles from './CompanyDashboardMobile.module.scss'

enum DashboardTab {
  Calendar,
  Orders,
  Restaurant,
}

const DashboardTabs = [
  DashboardTab.Calendar,
  DashboardTab.Orders,
  DashboardTab.Restaurant,
]

export default function CompanyDashboardMobile() {
  const { t } = useTranslation('preOrder')

  const newOrderStep = useAppSelector(preOrderNewOrderStepSelector)

  const [selectedTab, setSelectedTab] = useState<DashboardTab>(
    DashboardTab.Calendar,
  )

  const renderTabContent = useCallback(() => {
    switch (selectedTab) {
      case DashboardTab.Calendar:
        return <OrderCalendar />
      case DashboardTab.Orders:
        return <UpcomingOrders />
      case DashboardTab.Restaurant:
        return <SelectedRestaurant />
      default:
        return null
    }
  }, [selectedTab])

  return (
    <div className={styles.container}>
      {newOrderStep >= NewOrderStep.HostPreview ? (
        <NewOrderMobile />
      ) : (
        <div className={styles.dashboard}>
          <div className={styles.headerTabs}>
            {DashboardTabs.map(tab => (
              <Button
                key={tab}
                variant="secondary"
                className={clsx(styles.headerTabButton, {
                  [styles.selected]: tab === selectedTab,
                })}
                onClick={() => setSelectedTab(tab)}
              >
                {t(`dashboardTabs.${DashboardTab[tab].toLowerCase()}`)}
              </Button>
            ))}
          </div>
          <div className={styles.content}>{renderTabContent()}</div>
          <div className={styles.footer}>
            <PlaceNewOrder isMobile />
          </div>
        </div>
      )}
      <NewOrderModal />
    </div>
  )
}
