import useTranslation from 'next-translate/useTranslation'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import noop from 'lodash/noop'
import { usePrevious } from '@ancon/wildcat-utils/shared/hooks'

import Calendar from '../../../calendar/components/Calendar'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  setOrderCalendarSelectedMonth,
  setOrderCalenderSelectedDate,
} from '../../store/preOrderSlice'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderCalendarOrderDatesSelector,
  preOrderCalendarOrdersFetchPendingSelector,
  preOrderCalendarSelectedMonthSelector,
} from '../../store/preOrderSelectors'
import { fetchCalendarMonthPreOrders } from '../../store/preOrderThunks'
import { clientContextCurrentUserCompanyIdSelector } from '../../../clientContext/store/clientContextSelectors'
import useAppStore from '../../../../store/hooks/useAppStore'

import ScheduledOrders from './ScheduledOrders'
import styles from './OrderCalendar.module.scss'

export default function OrderCalendar() {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const orderedDates = useAppSelector(preOrderCalendarOrderDatesSelector)

  const isLoading = useAppSelector(preOrderCalendarOrdersFetchPendingSelector)

  const companyId = useAppSelector(clientContextCurrentUserCompanyIdSelector)

  const prevCompanyId = usePrevious(companyId)

  const [isRefreshing, setRefreshing] = useState<boolean>(false)

  useEffect(() => {
    async function initCalenderOrders() {
      const currentStartOfMonth = moment().startOf('month').format()
      await dispatch(
        fetchCalendarMonthPreOrders({ startOfMonth: currentStartOfMonth }),
      )
    }
    initCalenderOrders()
  }, [dispatch])

  function handleSelectDate(date: string) {
    dispatch(setOrderCalenderSelectedDate(date))
  }

  const refetchCalendarOrders = useCallback(
    (startOfMonth?: string, removeExisting?: boolean) => {
      dispatch(fetchCalendarMonthPreOrders({ startOfMonth, removeExisting }))
        .unwrap()
        .catch(noop)
        .finally(() => setRefreshing(false))
    },
    [dispatch],
  )

  const debouncedFetchOrders = useDebouncedCallback(refetchCalendarOrders, 300)

  useEffect(() => {
    const didCompanyChange =
      prevCompanyId && companyId && prevCompanyId !== companyId
    if (didCompanyChange) {
      setRefreshing(true)
      const calendarMonth = preOrderCalendarSelectedMonthSelector(
        store.getState(),
      )
      refetchCalendarOrders(calendarMonth, true)
    }
  }, [companyId, prevCompanyId, refetchCalendarOrders, store])

  function handleMonthChange(startOfMonth: string) {
    setRefreshing(true)
    dispatch(setOrderCalendarSelectedMonth(startOfMonth))
    debouncedFetchOrders(startOfMonth)
  }

  return (
    <div className={styles.container}>
      <Calendar
        className={styles.calendar}
        onSelectDate={handleSelectDate}
        onMonthChange={handleMonthChange}
        highlightedDates={orderedDates}
        highlightedDatesLabel={t('orderCalendar.orderedDays')}
        showLegend
        isLoading={isLoading || isRefreshing}
      />
      <ScheduledOrders />
    </div>
  )
}
