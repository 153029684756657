import moment from 'moment'
import useTranslation from 'next-translate/useTranslation'
import BasketIcon from '@ancon/wildcat-ui/shared/icons/basket-fill.svg'
import noop from 'lodash/noop'

import Button from '../../app/components/Button'
import BodyText from '../../app/components/BodyText'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { outletsSelectedRestaurantIdSelector } from '../../outlets/store/outletsSelectors'
import {
  preOrderCalendarSelectedDateSelector,
  preOrderSettingsFetchPendingSelector,
} from '../store/preOrderSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  preOrderGoNextNewOrderStep,
  setNewOrderDetails,
} from '../store/preOrderSlice'
import { NewOrderStep } from '../types'
import { fetchPreOrderSettings } from '../store/preOrderThunks'
import { setRestaurantSelectionModalVisible } from '../../outlets/store/outletsSlice'
import { RestaurantSelectionModalType } from '../../outlets/types'
import { outletSelectedOutletTenantIdSelector } from '../../outlet/store/outletSelector'
import useAppStore from '../../../store/hooks/useAppStore'

import styles from './PlaceNewOrder.module.scss'

interface PlaceNewOrderProps {
  isMobile?: boolean
}

export default function PlaceNewOrder({
  isMobile = false,
}: PlaceNewOrderProps) {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const selectedOutletId = useAppSelector(outletsSelectedRestaurantIdSelector)

  const preOrderSettingsFetchPending = useAppSelector(
    preOrderSettingsFetchPendingSelector,
  )

  const orderCalendarSelectedDate = useAppSelector(
    preOrderCalendarSelectedDateSelector,
  )

  const today = moment().format('YYYY-MM-DD')

  // Fallback to today if there is no selected date
  const orderPlaceDate = orderCalendarSelectedDate ?? today
  const isPrevDateSelected = moment(orderPlaceDate).isBefore(today, 'day')

  async function handleNewOrderClick() {
    if (selectedOutletId) {
      const tenantId = outletSelectedOutletTenantIdSelector(store.getState())
      try {
        if (tenantId) {
          await dispatch(fetchPreOrderSettings()).unwrap()
        }
      } catch (error) {
        noop()
      } finally {
        dispatch(
          preOrderGoNextNewOrderStep({
            step: NewOrderStep.OrderConfiguration,
            isMobile,
          }),
        )
        dispatch(setNewOrderDetails({ orderDate: orderPlaceDate }))
      }
    } else {
      dispatch(
        setRestaurantSelectionModalVisible(
          RestaurantSelectionModalType.PlaceNewOrder,
        ),
      )
    }
  }

  function getFormattedOrderPlaceDate() {
    return moment(orderPlaceDate).calendar({
      sameDay: `[${t('common:today')}], ll`,
      nextDay: `[${t('common:tomorrow')}], ll`,
      nextWeek: 'dddd, ll',
      sameElse: 'll',
    })
  }

  return (
    <div className={styles.container}>
      <BodyText className={styles.title} color="body-4">
        {isPrevDateSelected
          ? t('newOrder.selectFutureDate')
          : t('newOrder.placeOrderFor', { date: getFormattedOrderPlaceDate() })}
      </BodyText>
      <Button
        variant="primary"
        loading={preOrderSettingsFetchPending}
        onClick={handleNewOrderClick}
        disabled={isPrevDateSelected}
      >
        <BasketIcon />
        <BodyText as="span" fontSize="1.6rem">
          {t('newOrder.title')}
        </BodyText>
      </Button>
    </div>
  )
}
