import { OutletsFilters } from '../types'

export default function getOutletListQueryParams(filters: OutletsFilters) {
  const { location, searchTerm } = filters

  return {
    ...(location && {
      latitude: location.latitude,
      longitude: location.longitude,
    }),
    ...(searchTerm && { search: searchTerm }),
  }
}
