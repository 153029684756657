import clsx from 'clsx'

import Skeleton from '../../app/components/Skeleton'
import isAppWhiteLabeled from '../../app/utils/isAppWhiteLabeled'

import GreetingUserSkeleton from './greeting/GreetingUserSkeleton'
import OutletCardSkeleton from './outletCard/OutletCardSkeleton'
import styles from './OutletListSkeleton.module.scss'

interface OutletListSkeletonProps {
  containerClassName?: string
  isPageSkeleton?: boolean
}

export default function OutletListSkeleton({
  containerClassName,
  isPageSkeleton = true,
}: OutletListSkeletonProps) {
  const isWhiteLabeled = isAppWhiteLabeled()

  return (
    <div className={containerClassName}>
      <section>
        {isWhiteLabeled && isPageSkeleton && (
          <>
            <GreetingUserSkeleton />
            <Skeleton className={styles.searchInputSkeleton} />
          </>
        )}
        {!isWhiteLabeled && <Skeleton className={styles.groupHeaderSkeleton} />}
        <div
          className={clsx(styles.gridContainer, {
            [styles.wl]: isWhiteLabeled,
          })}
        >
          {new Array(12).fill(0).map((_, index) => (
            <OutletCardSkeleton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
        </div>
      </section>
    </div>
  )
}
