import useTranslation from 'next-translate/useTranslation'
import ArrowForwardIcon from '@ancon/wildcat-ui/shared/icons/arrow-forward-long.svg'
import clsx from 'clsx'

import Button from '../../../app/components/Button'
import BodyText from '../../../app/components/BodyText'

import styles from './NewOrderProceedButton.module.scss'

interface NewOrderProceedButtonProps {
  buttonText?: string
  buttonNote?: string
  disabled?: boolean
  loading?: boolean
  showNextIcon?: boolean
  onClick?: () => void
  className?: string
}

export default function NewOrderProceedButton({
  buttonText,
  buttonNote,
  disabled,
  loading,
  showNextIcon = true,
  onClick,
  className,
}: NewOrderProceedButtonProps) {
  const { t } = useTranslation('preOrder')

  return (
    <div className={clsx(styles.container, className)}>
      {buttonNote && (
        <BodyText
          className={styles.proceedNote}
          color="body-4"
          fontSize="1.2rem"
        >
          {buttonNote}
        </BodyText>
      )}
      <Button
        outlined
        className={styles.proceedButton}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
      >
        {buttonText ?? t('newOrder.continue')}
        {showNextIcon && <ArrowForwardIcon />}
      </Button>
    </div>
  )
}
