import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { setRestaurantSelectionModalVisible } from '../../store/outletsSlice'
import {
  outletsSelectedOutletListItemSelector,
  outletsSelectedRestaurantIdSelector,
} from '../../store/outletsSelectors'
import { preOrderIsNewOrderModeSelector } from '../../../preOrder/store/preOrderSelectors'
import { outletDetailsSelector } from '../../../outlet/store/outletSelector'
import { RestaurantSelectionModalType } from '../../types'

import Restaurant from './Restaurant'
import SelectYourRestaurantPlaceholder from './SelectYourRestaurantPlaceholder'
import styles from './SelectedRestaurant.module.scss'

export default function SelectedRestaurant() {
  const { t } = useTranslation('outlets')

  const dispatch = useAppDispatch()

  const selectedRestaurantId = useAppSelector(
    outletsSelectedRestaurantIdSelector,
  )

  const outletDetails = useAppSelector(outletDetailsSelector)

  const outletListItem = useAppSelector(outletsSelectedOutletListItemSelector)

  const isNewOrderMode = useAppSelector(preOrderIsNewOrderModeSelector)

  function handleSelectRestaurant() {
    dispatch(
      setRestaurantSelectionModalVisible(
        RestaurantSelectionModalType.RestaurantSelection,
      ),
    )
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.disabled]: isNewOrderMode,
      })}
    >
      <div className={styles.header}>
        <BodyText as="h3" color="body-2">
          {t('selectedRestaurant.title')}
        </BodyText>
        {selectedRestaurantId && (
          <Button
            variant="secondary"
            className={styles.selectButton}
            onClick={handleSelectRestaurant}
          >
            {t('selectedRestaurant.change')}
          </Button>
        )}
      </div>
      {selectedRestaurantId ? (
        <Restaurant
          outletDetails={outletDetails}
          outletListItem={outletListItem}
        />
      ) : (
        <SelectYourRestaurantPlaceholder />
      )}
    </div>
  )
}
