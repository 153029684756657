import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import Modal from '../../../app/components/modal/Modal'
import NewOrderConfigurationForm from '../../forms/NewOrderConfigurationForm'
import {
  preOrderIsNewOrderEditModeSelector,
  preOrderNewOrderModalStepSelector,
  preOrderNewOrderModalVisibleSelector,
} from '../../store/preOrderSelectors'
import {
  preOrderGoNextNewOrderStep,
  resetNewOrderDetails,
  setNewPreOrderEditModeStep,
  setNewPreOrderModalVisible,
} from '../../store/preOrderSlice'
import { NewOrderStep } from '../../types'
import useAppStore from '../../../../store/hooks/useAppStore'

import NewOrderMethodSelection from './NewOrderMethodSelection'
import styles from './NewOrderModal.module.scss'

export default function NewOrderModal() {
  const dispatch = useAppDispatch()

  const store = useAppStore()

  const isModalVisible = useAppSelector(preOrderNewOrderModalVisibleSelector)

  const currentModalStep = useAppSelector(preOrderNewOrderModalStepSelector)

  function handleModalDismiss() {
    const isEditMode = preOrderIsNewOrderEditModeSelector(store.getState())
    if (isEditMode) {
      dispatch(setNewPreOrderEditModeStep(NewOrderStep.None))
      dispatch(setNewPreOrderModalVisible(false))
    } else {
      dispatch(
        preOrderGoNextNewOrderStep({
          step: NewOrderStep.None,
          isMobile: true,
        }),
      )
      dispatch(resetNewOrderDetails())
    }
  }

  function renderModalContent() {
    switch (currentModalStep) {
      case NewOrderStep.OrderConfiguration:
        return <NewOrderConfigurationForm inModal isMobile />

      case NewOrderStep.OrderMethodSelection:
        return <NewOrderMethodSelection inModal isMobile />

      default:
        return null
    }
  }

  return (
    <Modal
      isOpen={isModalVisible}
      hideClose
      onClose={handleModalDismiss}
      mobileModalMode="bottom-sheet"
      contentClassName={styles.modalContent}
    >
      <div className={styles.modalBody}>{renderModalContent()}</div>
    </Modal>
  )
}
