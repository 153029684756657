import useTranslation from 'next-translate/useTranslation'
import { PreOrderStatus } from '@ancon/wildcat-types'
import noop from 'lodash/noop'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import { NewOrderStep } from '../../types'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { preOrderGoNextNewOrderStep } from '../../store/preOrderSlice'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { clientContextCustomerEmailSelector } from '../../../clientContext/store/clientContextSelectors'
import {
  groupPreOrderCreatePendingSelector,
  preOrderNewGroupOrderNameSelector,
  preOrderNewOrderDateSelector,
  preOrderNewOrderOrderFormatSelector,
  preOrderNewOrderSelectedOrderMethodSelector,
  preOrderNewOrderTimeSelector,
} from '../../store/preOrderSelectors'
import { outletsSelectedOutletListItemNameSelector } from '../../../outlets/store/outletsSelectors'
import { createGroupPreOrder } from '../../store/preOrderThunks'
import PreOrderConfigurationOverview, {
  PreOrderConfigField,
} from '../PreOrderConfigurationOverview'

import NewOrderHeader from './NewOrderHeader'
import NewOrderProceedButton from './NewOrderProceedButton'
import styles from './NewGroupOrderSummary.module.scss'

interface NewGroupOrderSummaryProps {
  onEdit?: (step: NewOrderStep) => void
  isMobile?: boolean
}

export default function NewGroupOrderSummary({
  onEdit,
  isMobile = false,
}: NewGroupOrderSummaryProps) {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  const outletName = useAppSelector(outletsSelectedOutletListItemNameSelector)
  const orderDate = useAppSelector(preOrderNewOrderDateSelector)
  const orderTimestamp = useAppSelector(preOrderNewOrderTimeSelector)
  const orderFormat = useAppSelector(preOrderNewOrderOrderFormatSelector)
  const orderMethod = useAppSelector(
    preOrderNewOrderSelectedOrderMethodSelector,
  )
  const groupOrderName = useAppSelector(preOrderNewGroupOrderNameSelector)
  const customerEmail = useAppSelector(clientContextCustomerEmailSelector)
  const isOrderSubmitting = useAppSelector(groupPreOrderCreatePendingSelector)

  async function handleContinue() {
    try {
      const groupOrder = await dispatch(createGroupPreOrder()).unwrap()
      const { preOrderStatus } = groupOrder

      switch (preOrderStatus) {
        case PreOrderStatus.ApprovalPending:
          dispatch(preOrderGoNextNewOrderStep(NewOrderStep.GroupOrderReceived))
          break
        // For auto accept setting ON case
        case PreOrderStatus.Activated:
          dispatch(preOrderGoNextNewOrderStep(NewOrderStep.ShareGroupOrderLink))
          break
        default:
          break
      }
    } catch (error) {
      noop()
    }
  }

  function handleEditNewOrder(dataType: PreOrderConfigField) {
    switch (dataType) {
      case PreOrderConfigField.OrderDate:
      case PreOrderConfigField.OrderTime:
      case PreOrderConfigField.OrderType:
        onEdit?.(NewOrderStep.OrderConfiguration)
        break
      case PreOrderConfigField.OrderMethod:
        onEdit?.(NewOrderStep.OrderMethodSelection)
        break
      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.summary}>
          <NewOrderHeader title={t('newOrder.summary')} isMobile={isMobile} />
          <div className={styles.summaryNote}>
            <BodyText as="label">{t('newOrder.summaryNote')}</BodyText>
          </div>
          <HeadingText as="h2" className={styles.summaryHeader}>
            {groupOrderName ?? t('newOrder.placeANewOrder')}
          </HeadingText>
          <PreOrderConfigurationOverview
            dataMapper={{
              [PreOrderConfigField.Restaurant]: outletName,
              [PreOrderConfigField.OrderDate]: orderDate,
              [PreOrderConfigField.OrderTime]: orderTimestamp,
              [PreOrderConfigField.OrderType]: orderFormat,
              [PreOrderConfigField.OrderMethod]: orderMethod,
              [PreOrderConfigField.Customer]: customerEmail,
            }}
            editable
            handleEdit={handleEditNewOrder}
          />
        </div>
      </div>
      <NewOrderProceedButton
        className={styles.footer}
        buttonText={t('newOrder.placeTheOrderRequest')}
        buttonNote={t('newOrder.ifAllGoodLetsProceed')}
        onClick={handleContinue}
        loading={isOrderSubmitting}
      />
    </div>
  )
}
