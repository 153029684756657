import { OutletListItem } from '@ancon/wildcat-types'
import clsx from 'clsx'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'
import { useRouter } from 'next/router'
import { batch } from 'react-redux'

import Card from '../../../layout/components/card/Card'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  outletsSetSelectedOutletListItem,
  outletsSetSelectedRestaurantId,
  outletsSetVisibleOrderFormatSelectionModal,
} from '../../store/outletsSlice'
import { OrderFormatSlugMap } from '../../../app/constants'
import isOutletsShowOutletBanner from '../../utils/isOutletsShowOutletBanner'
import isAppWhiteLabeled from '../../../app/utils/isAppWhiteLabeled'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { outletDetailsIdASelector } from '../../../outlet/store/outletSelector'
import { fetchOutletDetails } from '../../../outlet/store/outletThunks'
import { outletSetOutletDetails } from '../../../outlet/store/outletSlice'
import localStorageUtils from '../../../app/utils/localStorageUtils'

import OutletCardBanner from './OutletCardBanner'
import OutletCardOutletInfoSummary from './OutletCardOutletInfoSummary'
import styles from './OutletCard.module.scss'

interface OutletCardProps {
  outlet: OutletListItem
}

export default function OutletCard({ outlet }: OutletCardProps) {
  const dispatch = useAppDispatch()

  const router = useRouter()

  const { id: outletId, urlSlug } = outlet

  const showOutletBanner = isOutletsShowOutletBanner()
  const isWhiteLabeled = isAppWhiteLabeled()

  const selectedOutletId = useAppSelector(outletDetailsIdASelector)

  const orderFormats = getOutletOrderFormats(outlet)

  function handleOnClickOutletCard() {
    if (isWhiteLabeled) {
      if (selectedOutletId === outletId) {
        batch(() => {
          dispatch(outletsSetSelectedOutletListItem(null))
          dispatch(outletSetOutletDetails(null))
        })
      } else {
        batch(() => {
          dispatch(fetchOutletDetails({ outletId }))
          dispatch(outletsSetSelectedOutletListItem(outlet))
          dispatch(outletsSetSelectedRestaurantId(outlet.id)) // Need for company user WL dashboard
          localStorageUtils.setItem('preSelectedOutletId', outletId)
        })
      }
    } else if (orderFormats.length > 1) {
      // Open order format selection modal
      batch(() => {
        dispatch(outletsSetSelectedOutletListItem(outlet))
        dispatch(outletsSetVisibleOrderFormatSelectionModal(true))
      })
    } else {
      // Navigate to outlet page with order format
      const pathName = urlSlug ? `outlet/${urlSlug}` : `outlets/${outletId}`

      router.push({
        pathname: pathName,
        query: { orderFormat: OrderFormatSlugMap[orderFormats[0] as never] },
      })
    }
  }

  return (
    <Card
      className={clsx(styles.container, {
        [styles.selected]: isWhiteLabeled && selectedOutletId === outletId,
      })}
      onClick={handleOnClickOutletCard}
      tabIndex={0}
      aria-label={outlet.name}
    >
      {showOutletBanner && <OutletCardBanner outlet={outlet} />}
      <OutletCardOutletInfoSummary
        className={styles.outletInfo}
        outlet={outlet}
        showOutletStatus
      />
    </Card>
  )
}
