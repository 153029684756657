import { memo } from 'react'
import OutletLocationMarkerIcon from '@ancon/wildcat-ui/shared/static/placeholders/outlet-location-map-marker.svg'
import { OutletLocationCoordinates } from '@ancon/wildcat-types'

import { ScriptStatus, useScript } from '../../../app/hooks/useScript'
import { GoogleMapsAPIUrl } from '../../../app/constants'
import MapView from '../../../app/components/MapView'

import styles from './RestaurantMap.module.scss'

interface RestaurantMapProps {
  coordinates: OutletLocationCoordinates
}

function RestaurantMap({ coordinates }: RestaurantMapProps) {
  const scriptStatus = useScript(GoogleMapsAPIUrl)

  return (
    <div className={styles.mapContainer}>
      {scriptStatus === ScriptStatus.Ready && (
        <MapView
          markerCoordinates={coordinates}
          containerStyle={{
            height: '140px',
            position: 'relative',
          }}
          zoom={15}
          gestureHandling="none"
          fullscreenControl={false}
          markerConfig={{
            icon: {
              url: OutletLocationMarkerIcon.src,
              scaledSize: new window.google.maps.Size(50, 50),
            },
            optimized: true,
          }}
        />
      )}
    </div>
  )
}

export default memo(RestaurantMap)
