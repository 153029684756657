import { useState } from 'react'
import { OrderFormat } from '@ancon/wildcat-types'

import OutletDetailsModal from '../../../outlet/components/outletDetailsModal/OutletDetailsModal'
import NewOrderTimeConfigureModal from '../newOrderTimeConfigureModal/NewOrderTimeConfigureModal'
import { preOrderCheckoutOutletIdSelector } from '../../../preOrder/store/preOrderSelectors'
import useAppStore from '../../../../store/hooks/useAppStore'
import { outletsSelectedOutletListIdSelector } from '../../store/outletsSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import LeavePreOrderWarningModal from '../../../preOrder/components/LeavePreOrderWarningModal'
import { setPreOrderExistWarningModalVisible } from '../../../preOrder/store/preOrderSlice'

import NewOrderCardModal from './NewOrderCardModal'
import NewOrderCard from './NewOrderCard'

export default function NewOrder() {
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const [selectedOrderFormat, setSelectedOrderFormat] = useState<OrderFormat>()
  const [isVisibleTimeConfigurationModal, setIsVisibleTimeConfigurationModal] =
    useState(false)

  function clearSelectedOrderFormat() {
    setSelectedOrderFormat(undefined)
    setIsVisibleTimeConfigurationModal(false)
  }

  function handleOnClickOrderFormat(orderFormat: OrderFormat) {
    const preOrderOutletId = preOrderCheckoutOutletIdSelector(store.getState())
    const selectedOutletId = outletsSelectedOutletListIdSelector(
      store.getState(),
    )

    setSelectedOrderFormat(orderFormat)

    // Check wether pre-order exists
    if (preOrderOutletId === selectedOutletId) {
      dispatch(setPreOrderExistWarningModalVisible(true))
    } else {
      setIsVisibleTimeConfigurationModal(true)
    }
  }

  function handleOnStartNewOrder() {
    setIsVisibleTimeConfigurationModal(true)
  }

  function handleOnCloseLeavePreOrderWarningModal() {
    setSelectedOrderFormat(undefined)
  }

  return (
    <>
      <NewOrderCard onClickOrderFormat={handleOnClickOrderFormat} />
      <NewOrderCardModal onClickOrderFormat={handleOnClickOrderFormat} />
      <OutletDetailsModal isInOutletsPage />
      <NewOrderTimeConfigureModal
        isOpen={isVisibleTimeConfigurationModal}
        selectedOrderFormat={selectedOrderFormat}
        onClose={clearSelectedOrderFormat}
      />
      <LeavePreOrderWarningModal
        onStartNewOrder={handleOnStartNewOrder}
        onClose={handleOnCloseLeavePreOrderWarningModal}
      />
    </>
  )
}
