import { UrlObject } from 'url'

import { OrderFormat } from '@ancon/wildcat-types'

import { AppRoutes, OrderFormatSlugMap } from '../../app/constants'

export default function getPreOrderPageURL(
  outletSlug: string,
  orderFormat: OrderFormat,
): UrlObject {
  return {
    pathname: `${AppRoutes.Outlet}/${outletSlug}`,
    query: {
      orderFormat: OrderFormatSlugMap[orderFormat as never],
    },
  }
}
