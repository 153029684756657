import { useContext } from 'react'

import CalendarContext, { CalendarContextValue } from '../CalendarContext'

export default function useCalendarContext() {
  const context = useContext<CalendarContextValue>(CalendarContext)

  if (context === undefined) {
    throw new Error(
      'useCalendarContext must be used within a CalendarContext.Provider',
    )
  }

  return context
}
