import { Moment } from 'moment'

export default function generateCalendarWeeks(monthMoment: Moment) {
  const startOfMonth = monthMoment.clone().startOf('month')
  const endOfMonth = monthMoment.clone().endOf('month')

  const firstWeekYear = startOfMonth.isoWeekYear()
  const lastWeekYear = endOfMonth.isoWeekYear()

  const firstWeek = startOfMonth.isoWeek()
  const lastWeek = endOfMonth.isoWeek()

  const weeks = []

  if (firstWeekYear < lastWeekYear) {
    // If week years are different
    const month = startOfMonth.month() + 1

    if (month === 1) {
      // If current month is January

      /** Last week of the previous year */
      weeks.push({ year: firstWeekYear, week: firstWeek })

      /** Rest of the weeks of the current year */
      for (let w = 1; w <= lastWeek; w += 1) {
        weeks.push({ year: lastWeekYear, week: w })
      }
    } else if (month === 12) {
      // If current month is December

      /** First weeks of the current year */
      for (let w = firstWeek; w <= startOfMonth.weeksInYear(); w += 1) {
        weeks.push({ year: firstWeekYear, week: w })
      }

      /** First week of the next year */
      weeks.push({ year: lastWeekYear, week: lastWeek })
    }
  } else if (firstWeekYear === lastWeekYear) {
    // If week years are the same
    for (let w = firstWeek; w <= lastWeek; w += 1) {
      weeks.push({ year: firstWeekYear, week: w })
    }
  }

  return weeks
}
