import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { PreOrderStatus } from '@ancon/wildcat-types'

import BodyText from '../../app/components/BodyText'

import styles from './GroupOrderApprovalStatusTag.module.scss'

interface GroupOrderApprovalStatusTagProps {
  status: PreOrderStatus
  className?: string
}

export default function GroupOrderApprovalStatusTag({
  status,
  className,
}: GroupOrderApprovalStatusTagProps) {
  const { t } = useTranslation('common')

  const isPreOrderStarted = status >= PreOrderStatus.Started

  function getStatusText() {
    switch (status) {
      case PreOrderStatus.ApprovalPending:
        return t('preOrder.groupOrderStatus.approvalPending')
      case PreOrderStatus.Activated:
        return t('preOrder.groupOrderStatus.activated')
      default:
        return ''
    }
  }

  if (isPreOrderStarted) {
    return null
  }

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.pending]: status === PreOrderStatus.ApprovalPending,
          [styles.active]: status === PreOrderStatus.Activated,
        },
        className,
      )}
    >
      <BodyText as="span" color="body-3">
        {getStatusText()}
      </BodyText>
    </div>
  )
}
