import useTranslation from 'next-translate/useTranslation'
import HandIcon from '@ancon/wildcat-ui/shared/icons/hand-click-outline.svg'

import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { setRestaurantSelectionModalVisible } from '../../store/outletsSlice'
import { RestaurantSelectionModalType } from '../../types'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { outletsSelectedRestaurantIdSelector } from '../../store/outletsSelectors'

import styles from './SelectYourRestaurantPlaceholder.module.scss'

export default function SelectYourRestaurantPlaceholder() {
  const { t } = useTranslation('outlets')
  const dispatch = useAppDispatch()
  const selectedRestaurantId = useAppSelector(
    outletsSelectedRestaurantIdSelector,
  )

  function handleSelectRestaurant() {
    dispatch(
      setRestaurantSelectionModalVisible(
        RestaurantSelectionModalType.RestaurantSelection,
      ),
    )
  }

  return (
    <div className={styles.placeholderContainer}>
      <HandIcon />
      <BodyText>{t('selectYourPreferredOutlet')}</BodyText>
      {!selectedRestaurantId && (
        <Button
          variant="secondary"
          className={styles.selectRestaurantButton}
          onClick={handleSelectRestaurant}
        >
          {t('selectedRestaurant.selectRestaurant')}
        </Button>
      )}
    </div>
  )
}
