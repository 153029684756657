import moment, { Moment } from 'moment'
import clsx from 'clsx'

import InteractiveDiv from '../../layout/components/InteractiveDiv'
import BodyText from '../../app/components/BodyText'
import useCalendarContext from '../hooks/useCalendarContext'

import styles from './CalendarDate.module.scss'

export type CalendarDateProps = {
  date: Moment
  onSelect?: (date: Moment) => void
}

export default function CalendarDate({ date, onSelect }: CalendarDateProps) {
  const { currentMonth, selectedDate, highlightedDates, minDate } =
    useCalendarContext()

  const isOutsideOfMonth = date.month() !== currentMonth.month()
  const isSelectedDate = date.isSame(selectedDate, 'date')
  const isToday = date.isSame(moment(), 'date')
  const isHighlighted = highlightedDates?.some(hd => date.isSame(hd, 'date'))
  const isBeforeMinDate = !!minDate && date.isBefore(minDate, 'date')

  const disabled = isOutsideOfMonth || isBeforeMinDate

  function handleClickDate() {
    onSelect?.(date)
  }

  return (
    <InteractiveDiv
      className={clsx(
        styles.dateContainer,
        {
          [styles.outsideOfMonth]: isOutsideOfMonth,
          [styles.selected]: isSelectedDate,
          [styles.beforeMin]: isBeforeMinDate,
          [styles.disabled]: disabled,
        },
        !isSelectedDate && {
          [styles.today]: isToday,
          [styles.highlighted]: !isOutsideOfMonth && !isToday && isHighlighted,
        },
      )}
      onClick={handleClickDate}
      disabled={disabled}
    >
      <BodyText as="span">{moment(date).format('D')}</BodyText>
    </InteractiveDiv>
  )
}
