import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export default function useIsRouteChanging(onComplete?: () => void): boolean {
  const [isChanging, setChanging] = useState<boolean>(false)

  const router = useRouter()

  useEffect(() => {
    function handleRouteChangeStart() {
      setChanging(true)
    }

    function handleRouteChangeComplete() {
      setChanging(false)
      onComplete?.()
    }

    router.events?.on('routeChangeStart', handleRouteChangeStart)
    router.events?.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events?.off('routeChangeStart', handleRouteChangeStart)
      router.events?.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [onComplete, router.events])

  return isChanging
}
