import Skeleton from '@ancon/wildcat-ui/web/orderweb/Skeleton'

import isAppWhiteLabeled from '../../app/utils/isAppWhiteLabeled'
import Card from '../../layout/components/card/Card'

import OutletListSkeleton from './OutletListSkeleton'
import styles from './OutletsListPageSkeleton.module.scss'
import NewOrderOutletDetailsSkeleton from './newOrder/NewOrderOutletDetailsSkeleton'

export default function OutletsListPageSkeleton() {
  const isWhiteLabeled = isAppWhiteLabeled()

  return (
    <div className={styles.wrapper}>
      {/* Left Container */}
      <OutletListSkeleton containerClassName={styles.skeletonContainer} />
      {/* Right Container */}
      {isWhiteLabeled && (
        <div className={styles.rightContainer}>
          <Card className={styles.newOrderCard}>
            <Skeleton className={styles.newOrderTitle} />
            <NewOrderOutletDetailsSkeleton />
          </Card>
        </div>
      )}
    </div>
  )
}
