import React from 'react'
import { Moment, weekdaysMin } from 'moment'

import BodyText from '../../app/components/BodyText'
import generateCalendarWeeks from '../utils/generateCalendarWeeks'
import getCalendarWeekDates from '../utils/getCalendarWeekDates'
import useCalendarContext from '../hooks/useCalendarContext'

import CalendarDate from './CalendarDate'
import styles from './CalendarMonth.module.scss'

interface CalendarMonthProps {
  onSelectDate?: (date: Moment) => void
}

export default function CalendarMonth({ onSelectDate }: CalendarMonthProps) {
  const { currentMonth, isLoading } = useCalendarContext()

  return (
    <div className={styles.container}>
      <div className={styles.calendarWeekDays}>
        {weekdaysMin(true).map(weekDay => (
          <BodyText key={weekDay} as="span" color="body-2">
            {weekDay}
          </BodyText>
        ))}
      </div>
      <div className={styles.calendarMonth}>
        {generateCalendarWeeks(currentMonth).map(calendarWeek => (
          <div key={calendarWeek.week} className={styles.calendarWeek}>
            {getCalendarWeekDates(calendarWeek).map(date => (
              <CalendarDate date={date} onSelect={onSelectDate} />
            ))}
          </div>
        ))}
        {!!isLoading && <div className={styles.shimmerOverlay} />}
      </div>
    </div>
  )
}
