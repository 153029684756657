import clsx from 'clsx'

import Card from '../../../layout/components/card/Card'
import Skeleton from '../../../app/components/Skeleton'
import isOutletsShowOutletBanner from '../../utils/isOutletsShowOutletBanner'

import styles from './OutletCardSkeleton.module.scss'

interface OutletCardSkeletonProps {
  className?: string
}

export default function OutletCardSkeleton({
  className,
}: OutletCardSkeletonProps) {
  const isShowOutletBanner = isOutletsShowOutletBanner()

  return (
    <Card className={clsx(styles.container, className)}>
      {isShowOutletBanner && (
        <div className={styles.topContainer}>
          <Skeleton className={styles.topContainer}>
            <div className={styles.logoPlaceholder} />
          </Skeleton>
        </div>
      )}
      <div className={styles.infoContainer}>
        <Skeleton className={styles.outletName} />
        <Skeleton className={styles.outletAddress} />
      </div>
      <div className={styles.footerRow}>
        <div className={styles.leftFooterContainer}>
          <Skeleton className={styles.footerMeta} />
          <Skeleton className={styles.footerMeta} />
        </div>

        <Skeleton className={styles.footerMeta} />
      </div>
    </Card>
  )
}
