import useTranslation from 'next-translate/useTranslation'
import { CustomerPreOrderCheckout } from '@ancon/wildcat-types'
import BasketIcon from '@ancon/wildcat-ui/shared/icons/basket-fill.svg'
import moment from 'moment'
import clsx from 'clsx'
import isPreOrderSharable from '@ancon/wildcat-utils/preOrder/isPreOrderSharable'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'

import styles from './UpcomingOrderItem.module.scss'

interface UpcomingOrderItemProps {
  orderCheckout: CustomerPreOrderCheckout
  onClick?: (id: string) => void
  showOrderDate?: boolean
  showIcon?: boolean
}

export default function UpcomingOrderItem({
  orderCheckout,
  onClick,
  showOrderDate = false,
  showIcon = false,
}: UpcomingOrderItemProps) {
  const { t } = useTranslation('preOrder')

  const { id, preOrderName, ticketNumber, serviceTime, attendeeCount } =
    orderCheckout

  function handleClickOrder() {
    onClick?.(id)
  }

  function getServiceDateTime() {
    return moment(serviceTime.time).calendar({
      sameDay: `[${t('common:today')}] [${t('common:at')}] LT`,
      nextDay: `[${t('common:tomorrow')}] [${t('common:at')}] LT`,
      nextWeek: `dddd [${t('common:at')}] LT`,
      sameElse: 'LL, LT',
    })
  }

  return (
    <InteractiveDiv className={styles.container} onClick={handleClickOrder}>
      {showIcon && <BasketIcon />}
      <div className={styles.orderDetails}>
        {showOrderDate && (
          <BodyText className={styles.serviceTime} color="body-1">
            {getServiceDateTime()}
          </BodyText>
        )}
        <HeadingText as="h3" color="heading-1">
          {preOrderName ??
            t('upcomingOrders.orderNumber', { orderNumber: ticketNumber })}
        </HeadingText>
        {isPreOrderSharable(orderCheckout) && (
          <BodyText
            className={clsx({
              [styles.lowercase]: attendeeCount > 0,
            })}
            color="body-1"
          >
            {attendeeCount > 0
              ? t('attendeesCount', { count: attendeeCount })
              : t('noAttendees')}
          </BodyText>
        )}
      </div>
    </InteractiveDiv>
  )
}
