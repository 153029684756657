import Skeleton from '../../../../features/app/components/Skeleton'

import styles from './PreOrderItemListSkeleton.module.scss'

export default function PreOrderItemListSkeleton() {
  return (
    <div>
      <Skeleton className={styles.header} />
      <div className={styles.orderItemList}>
        {Array.from(Array(4).keys()).map(index => (
          <div key={index} className={styles.orderItem}>
            <div className={styles.orderItemRight}>
              <Skeleton className={styles.orderItemQty} />
              <Skeleton className={styles.orderItemName} />
            </div>
            <Skeleton className={styles.orderItemPrice} />
          </div>
        ))}
      </div>
    </div>
  )
}
