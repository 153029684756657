import { CheckoutItem } from '@ancon/wildcat-types'

import CartItemList from '../../../checkout/components/shoppingCart/CartItemList'

import styles from './PreOrderItemList.module.scss'

interface PreOrderItemListProps {
  items: CheckoutItem[]
  emptyListPlaceholder?: React.ReactNode
}

export default function PreOrderItemList({
  items,
  emptyListPlaceholder,
}: PreOrderItemListProps) {
  return (
    <CartItemList
      items={items}
      className={styles.itemListContainer}
      itemContainerClassName={styles.itemContainer}
      itemQuantityClassName={styles.itemQuantity}
      showItemSeparator={false}
      emptyListPlaceholder={emptyListPlaceholder}
      itemDisabled
    />
  )
}
