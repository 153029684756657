import { useCallback, useMemo, useState } from 'react'
import noop from 'lodash/noop'

const CopiedTimeout = 1500

type UseClipboardReturn = {
  isCopied: boolean
  copyText: (text: string) => Promise<() => void>
}

export default function useClipboard(): UseClipboardReturn {
  const [isCopied, setCopied] = useState<boolean>(false)

  const writeTextToClipboard = useCallback(
    async (text: string) => {
      // Ignore copied timeout when unmount
      let ignore: boolean = false
      let timer: NodeJS.Timeout

      try {
        await navigator.clipboard.writeText(text)
        if (!isCopied) {
          setCopied(true)
          timer = setTimeout(() => {
            if (!ignore) {
              setCopied(false)
            }
          }, CopiedTimeout)
        }
      } catch (error) {
        noop()
      }

      return () => {
        if (timer) {
          ignore = true
          clearTimeout(timer)
        }
      }
    },
    [isCopied],
  )

  return useMemo<UseClipboardReturn>(
    () => ({
      isCopied,
      copyText: writeTextToClipboard,
    }),
    [isCopied, writeTextToClipboard],
  )
}
