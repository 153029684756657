import useTranslation from 'next-translate/useTranslation'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'
import { PreOrderAttendee } from '@ancon/wildcat-types'
import getTotalCheckoutItemsQuantity from '@ancon/wildcat-utils/checkout/getTotalCheckoutItemsQuantity'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'
import Accordion from '../../../app/components/Accordion'
import useToggleState from '../../../app/hooks/useToggleState'
import EmptyPlaceholder from '../../../app/components/emptyPlaceholder/EmptyPlaceholder'
import calculateAttendeeItemsTotalAmount from '../../utils/calculateAttendeeItemsTotalAmount'

import PreOrderItemList from './PreOrderItemList'
import styles from './PreOrderAttendeeAccordion.module.scss'

interface PreOrderAttendeeAccordionProps {
  attendee: PreOrderAttendee
}

export default function PreOrderAttendeeAccordion({
  attendee,
}: PreOrderAttendeeAccordionProps) {
  const { t } = useTranslation('preOrder')

  const [isExpanded, toggle] = useToggleState(false)

  const { name, items } = attendee

  const totalItemsQty = getTotalCheckoutItemsQuantity(items)
  const attendeeItemsTotalAmount = calculateAttendeeItemsTotalAmount(items)

  return (
    <Accordion
      containerClassName={clsx(styles.accordion, {
        [styles.expanded]: isExpanded,
      })}
      headerContainerClassName={styles.headerContainer}
      caretClassName={styles.caret}
      onToggle={toggle}
      expanded={isExpanded}
      title={
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <BodyText fontSize="1.6rem">{name}</BodyText>
            <BodyText
              color="body-1"
              className={clsx(styles.headerItemsCount, {
                [styles.expanded]: isExpanded,
              })}
            >
              {t('common:itemsCount', { count: totalItemsQty })}
            </BodyText>
          </div>
          {totalItemsQty > 0 && (
            <BodyText className={styles.headerAttendeeTotal} fontSize="1.6rem">
              {getFormattedCurrency(
                attendeeItemsTotalAmount?.amount,
                attendeeItemsTotalAmount?.currency,
              )}
            </BodyText>
          )}
        </div>
      }
    >
      <PreOrderItemList
        items={items}
        emptyListPlaceholder={
          <EmptyPlaceholder
            className={styles.emptyAttendeeItemList}
            title={t('emptyAttendeeItems.title')}
            message={t('emptyAttendeeItems.message')}
          />
        }
      />
    </Accordion>
  )
}
