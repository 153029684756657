import {
  CheckoutDetails,
  OrderSummary,
  CustomerOrder,
  CustomerOrderDetails,
  OrderFormat,
  OrderStatus,
  CheckoutOrderType,
  PreOrderStatus,
  CustomerCheckoutListItemAttendee,
  CustomerCheckoutListItem,
  CheckoutItem,
  Amount,
  TicketStatus,
  TicketItemStatus,
} from '@ancon/wildcat-types'
import { EntityState } from '@reduxjs/toolkit'

export type OrderReducerState = {
  activeOrders: EntityState<CheckoutDetails>
  activeOrderFetchPending: boolean

  pastOrders: EntityState<CustomerOrder>
  pastOrdersFetchPending: boolean

  upcomingOrders: EntityState<CustomerCheckoutListItem>
  upcomingOrdersFetchPending: boolean

  orderDetailsFetchPending: boolean
  orderDetails: CustomerOrderDetails | null

  orderReceiptSendPending: boolean
  isReceiptDownloadPending: boolean

  checkOrderSummary: OrderSummary | null
  checkOrderSummaryTickets: EntityState<CheckOrderTicketEntity>
  checkOrderSummaryFetchPending: boolean
}

export type QROrderStatusPageQuery = {
  orderId: string
}

export interface OrderSummaryProps {
  id: string
  outlet: {
    id: string
    name: string
    logoId: string | null
  }
  status: OrderStatus
  orderFormat: OrderFormat
  createdAt: string
  serviceTime?: {
    time: string
  }
  totalAmount: Amount
  items?: CheckoutItem[]
  attendees?: CustomerCheckoutListItemAttendee[]
  ticketNumber: number
  orderType?: CheckoutOrderType
  hostAttendeeId?: string
  preOrderInfo?: {
    name: string
    status: PreOrderStatus
  }
  totalAmountPlusTip: Amount
}

export enum OrdersTab {
  Active = 'ongoing',
  Past = 'past',
  Upcoming = 'upcoming',
}

export type MyOrdersPageQuery = {
  tab?: OrdersTab
}

export type CheckOrderTicketEntityTicketItem = {
  id: string
  status: TicketItemStatus
  orderItemId: string
  /** Ticket items of sub-products, if order item is a multi-product */
  ticketItems: Array<{
    id: string
    status: TicketItemStatus
  }>
  hasModifications: boolean
}

export type CheckOrderTicketEntity = {
  id: string
  status: TicketStatus
  ticketItems: Array<CheckOrderTicketEntityTicketItem>
}
