import useAppSelector from '../../../../store/hooks/useAppSelector'
import { preOrderNewOrderStepSelector } from '../../store/preOrderSelectors'
import { NewOrderStep } from '../../types'
import NewOrderConfigurationForm from '../../forms/NewOrderConfigurationForm'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  preOrderGoNextNewOrderStep,
  setNewPreOrderEditModeStep,
} from '../../store/preOrderSlice'

import NewOrderMethodSelection from './NewOrderMethodSelection'
import NewGroupOrderHostPreview from './NewGroupOrderHostPreview'
import NewGroupOrderSummary from './NewGroupOrderSummary'
import GroupOrderRequestReceived from './GroupOrderRequestReceived'
import GroupOrderSharedLink from './GroupOrderSharedLink'
import styles from './NewOrder.module.scss'

export default function NewOrder() {
  const dispatch = useAppDispatch()

  const newOrderStep = useAppSelector(preOrderNewOrderStepSelector)

  function handleEditNewGroupOrder(step: NewOrderStep) {
    dispatch(preOrderGoNextNewOrderStep(step))
    dispatch(setNewPreOrderEditModeStep(step))
  }

  function renderPlaceNewOrderStep() {
    switch (newOrderStep) {
      case NewOrderStep.OrderConfiguration:
        return <NewOrderConfigurationForm />

      case NewOrderStep.OrderMethodSelection:
        return <NewOrderMethodSelection />

      case NewOrderStep.HostPreview:
        return <NewGroupOrderHostPreview />

      case NewOrderStep.Summary:
        return <NewGroupOrderSummary onEdit={handleEditNewGroupOrder} />

      case NewOrderStep.GroupOrderReceived:
        return <GroupOrderRequestReceived />

      case NewOrderStep.ShareGroupOrderLink:
        return <GroupOrderSharedLink />

      default:
        return null
    }
  }

  return <div className={styles.container}>{renderPlaceNewOrderStep()}</div>
}
