import useTranslation from 'next-translate/useTranslation'
import OrderFileIcon from '@ancon/wildcat-ui/shared/icons/order-file-certificate.svg'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import { NewOrderStep } from '../../types'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  preOrderGoNextNewOrderStep,
  resetNewOrderDetails,
} from '../../store/preOrderSlice'

import NewOrderProceedButton from './NewOrderProceedButton'
import styles from './GroupOrderRequestReceived.module.scss'

export default function GroupOrderRequestReceived() {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  function handleDone() {
    dispatch(preOrderGoNextNewOrderStep(NewOrderStep.None))
    dispatch(resetNewOrderDetails())
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.orderReceivedNote}>
          <div className={styles.noteHeader}>
            <HeadingText as="h3" color="heading-1">
              {t('newOrder.almostDone')}
            </HeadingText>
            <OrderFileIcon />
            <HeadingText as="h2">
              {t('newOrder.weHaveReceivedYourOrderRequest')}
            </HeadingText>
          </div>
          <BodyText color="body-1">
            {t('newOrder.youWillGetUpdatesOnceOrderRequestApproved')}
          </BodyText>
        </div>
      </div>
      <NewOrderProceedButton
        className={styles.footer}
        buttonText={t('newOrder.done')}
        buttonNote={t('newOrder.letYouKnowShortly')}
        showNextIcon={false}
        onClick={handleDone}
      />
    </div>
  )
}
