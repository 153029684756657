'use client'

import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { setAuthCompanySignUpModalVisible } from '../../auth/store/authSlice'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { authCustomerIsLoggedInSelector } from '../../auth/store/authSelectors'
import { clientContextFetchPendingSelector } from '../../clientContext/store/clientContextSelectors'

const CompanySignUpFilterKey = 'companysignup'

export default function CompanySignUpURLFilter() {
  const dispatch = useAppDispatch()

  const router = useRouter()

  const isTriggeredRef = useRef<boolean>(false)

  const isLoggedIn = useAppSelector(authCustomerIsLoggedInSelector)

  const isClientContextLoading = useAppSelector(
    clientContextFetchPendingSelector,
  )

  useEffect(() => {
    const { query } = router

    const hasCompanySignUpFlag = Object.keys(query).find(
      key => key.toLowerCase() === CompanySignUpFilterKey,
    )

    if (isLoggedIn) {
      // Remove the flag from the URL if the user is logged in
      if (hasCompanySignUpFlag) {
        delete query[CompanySignUpFilterKey]
        router.replace({ pathname: '/', query })
      }
    } else if (
      isLoggedIn === false &&
      hasCompanySignUpFlag &&
      !isTriggeredRef.current &&
      !isClientContextLoading
    ) {
      isTriggeredRef.current = true
      dispatch(setAuthCompanySignUpModalVisible(true))
    }
  }, [dispatch, isClientContextLoading, isLoggedIn, router])

  return null
}
