import HeadingText from '@ancon/wildcat-ui/web/orderweb/HeadingText'
import moment from 'moment'
import useTranslation from 'next-translate/useTranslation'
import HandIcon from '@ancon/wildcat-ui/shared/icons/hand-click-outline.svg'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import { useMemo } from 'react'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import { clientContextCustomerFirstNameSelector } from '../../../clientContext/store/clientContextSelectors'

import styles from './GreetingUser.module.scss'

export default function GreetingUser() {
  const { t } = useTranslation('outlets')

  const name = useAppSelector(clientContextCustomerFirstNameSelector)

  const greetingMessage = useMemo(() => {
    function getGreetingText() {
      const hour = moment().get('hour')

      if (hour < 12) {
        return t('greetingMessages.morning')
      }
      if (hour < 17) {
        return t('greetingMessages.afternoon')
      }
      return t('greetingMessages.evening')
    }

    const greeting = getGreetingText()

    return name ? `${greeting}, ${name}` : greeting
  }, [name, t])

  return (
    <div className={styles.container}>
      <HeadingText as="h2">{greetingMessage}</HeadingText>
      <div className={styles.row}>
        <HandIcon />
        <BodyText fontSize="1.6rem">{t('selectYourPreferredOutlet')}</BodyText>
      </div>
    </div>
  )
}
