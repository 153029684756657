import clsx from 'clsx'

import GreetingUser from '../../outlets/components/greeting/GreetingUser'
import SelectedRestaurant from '../../outlets/components/selectedRestaurant/SelectedRestaurant'
import UpcomingOrders from '../../preOrder/components/upcomingOrders/UpcomingOrders'
import { preOrderIsNewOrderModeSelector } from '../../preOrder/store/preOrderSelectors'
import useAppSelector from '../../../store/hooks/useAppSelector'
import OrderCalendar from '../../preOrder/components/orderCalendar/OrderCalendar'
import PlaceNewOrder from '../../preOrder/components/PlaceNewOrder'
import NewOrder from '../../preOrder/components/newOrder/NewOrder'

import styles from './CompanyDashboardDesktop.module.scss'

export default function CompanyDashboardDesktop() {
  const isNewOrderMode = useAppSelector(preOrderIsNewOrderModeSelector)

  return (
    <div
      className={clsx(styles.container, {
        [styles.overlay]: isNewOrderMode,
      })}
    >
      <div className={styles.dashboard}>
        <GreetingUser />
        <div className={styles.sections}>
          <SelectedRestaurant />
          <UpcomingOrders />
          {isNewOrderMode ? (
            <NewOrder />
          ) : (
            <div className={styles.orderScheduler}>
              <OrderCalendar />
              <PlaceNewOrder />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
