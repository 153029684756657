import Skeleton from '../../../app/components/Skeleton'

import styles from './PreOrderTotalSkeleton.module.scss'

export default function PreOrderTotalSkeleton() {
  return (
    <div className={styles.totalContainer}>
      <div className={styles.row}>
        <Skeleton className={styles.subTotalText} />
        <Skeleton className={styles.subTotalValue} />
      </div>
      <div className={styles.separator} />
      <div className={styles.row}>
        <div className={styles.column}>
          <Skeleton className={styles.totalText} />
          <Skeleton className={styles.vatIncludedText} />
        </div>
        <Skeleton className={styles.totalValue} />
      </div>
    </div>
  )
}
