import React, { useEffect, useMemo, useState } from 'react'
import moment, { Moment } from 'moment'
import ChevronLeftIcon from '@ancon/wildcat-ui/shared/icons/chevron-left.svg'
import ChevronRightIcon from '@ancon/wildcat-ui/shared/icons/chevron-right.svg'
import clsx from 'clsx'

import HeadingText from '../../app/components/HeadingText'
import Button from '../../app/components/Button'
import CalendarContext, { CalendarContextValue } from '../CalendarContext'
import { CalendarDefaultDateFormat, CalendarMonthFormat } from '../constants'

import CalendarLegend from './CalendarLegend'
import CalendarMonth from './CalendarMonth'
import styles from './Calendar.module.scss'

interface CalendarProps {
  /** Initial selected date */
  initialSelectedDate?: string

  /** Callback function when a date is selected */
  onSelectDate?: (date: string) => void

  /** Callback function when the month changes */
  onMonthChange?: (startDateOfMonth: string) => void

  /** List of dates to highlight */
  highlightedDates?: string[]

  /** Label for highlighted dates to be used in the legend */
  highlightedDatesLabel?: string

  /**
   * Whether to show the legend
   * @default false
   */
  showLegend?: boolean

  /** Minimum date that can be selected */
  minDate?: string | Moment

  /** Date format to be used for the selected date */
  dateFormat?: string

  /** Whether the calendar is loading */
  isLoading?: boolean

  /** Additional class name */
  className?: string
}

export default function Calendar({
  initialSelectedDate,
  onSelectDate,
  onMonthChange,
  highlightedDates,
  highlightedDatesLabel,
  showLegend,
  minDate,
  dateFormat = CalendarDefaultDateFormat,
  isLoading = false,
  className,
}: CalendarProps) {
  const [currentMonth, setCurrentMonth] = useState<Moment>(
    moment().startOf('month'),
  )
  const [selectedDate, setSelectedDate] = useState<string | null>(null)

  const isPrevMonthDisabled = !!minDate && currentMonth.isSame(minDate, 'month')

  useEffect(() => {
    if (initialSelectedDate) {
      setCurrentMonth(moment(initialSelectedDate).clone().startOf('month'))
    }
  }, [initialSelectedDate])

  function handleGoToPreviousMonth() {
    const prevMonth = currentMonth.clone().subtract(1, 'month')
    onMonthChange?.(prevMonth.format(dateFormat))
    setCurrentMonth(prevMonth)
  }

  function handleGoToNextMonth() {
    const nextMonth = currentMonth.clone().add(1, 'month')
    onMonthChange?.(nextMonth.format(dateFormat))
    setCurrentMonth(nextMonth)
  }

  function handleSelectDate(date: Moment) {
    const dateString = date.format(dateFormat)

    setSelectedDate(dateString)
    onSelectDate?.(dateString)
  }

  const calendarContextValue = useMemo<CalendarContextValue>(
    () => ({
      currentMonth,
      selectedDate,
      highlightedDates: highlightedDates ?? [],
      minDate,
      isLoading,
    }),
    [currentMonth, highlightedDates, isLoading, minDate, selectedDate],
  )

  return (
    <CalendarContext.Provider value={calendarContextValue}>
      <div className={clsx(styles.calendar, className)}>
        <div className={styles.calendarHeader}>
          <Button
            plain
            disabled={isPrevMonthDisabled}
            onClick={handleGoToPreviousMonth}
          >
            <ChevronLeftIcon />
          </Button>
          <HeadingText as="h3">
            {currentMonth.format(CalendarMonthFormat)}
          </HeadingText>
          <Button plain onClick={handleGoToNextMonth}>
            <ChevronRightIcon />
          </Button>
        </div>
        <CalendarMonth onSelectDate={handleSelectDate} />
        {showLegend && (
          <CalendarLegend highlightedDayLabel={highlightedDatesLabel} />
        )}
      </div>
    </CalendarContext.Provider>
  )
}
