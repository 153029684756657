import moment, { Moment } from 'moment'
import { createContext } from 'react'

export type CalendarContextValue = {
  currentMonth: Moment
  selectedDate: string | null
  highlightedDates: string[]
  minDate?: string | Moment
  isLoading?: boolean
}

const CalendarContext = createContext<CalendarContextValue>({
  currentMonth: moment().startOf('month'),
  selectedDate: null,
  highlightedDates: [],
  minDate: undefined,
  isLoading: false,
})

export default CalendarContext
