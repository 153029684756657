import Skeleton from '@ancon/wildcat-ui/web/orderweb/Skeleton'
import clsx from 'clsx'

import styles from './NewOrderOutletDetailsSkeleton.module.scss'

export default function NewOrderOutletDetailsSkeleton() {
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.left}>
          <Skeleton className={styles.outletNameText} />
        </div>
        <div className={styles.right}>
          <Skeleton className={styles.infoButton} />
        </div>
      </div>
      <Skeleton className={clsx(styles.bodyText, styles.addressText)} />
      <div className={clsx(styles.orderFormatsWrapper, styles.row)}>
        <Skeleton className={styles.orderFormat} />
        <Skeleton className={styles.orderFormat} />
      </div>
      <div className={styles.contactInfoRows}>
        {new Array(2).fill(0).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.contactInfoRow}>
            <Skeleton className={styles.contactBodyText} />
          </div>
        ))}
      </div>
    </div>
  )
}
