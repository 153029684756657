import moment from 'moment'

import { CalenderWeek } from '../types'

export default function getCalendarWeekDates(calendarWeek: CalenderWeek) {
  const dates = []
  for (let i = 0; i < 7; i += 1) {
    const date = moment()
      .isoWeekYear(calendarWeek.year)
      .isoWeek(calendarWeek.week)
      .isoWeekday(i + 1)

    dates.push(date)
  }

  return dates
}
