import clsx from 'clsx'

import Skeleton from '../../../../features/app/components/Skeleton'

// eslint-disable-next-line @jespers/css-modules/no-unused-classes
import styles from './UpcomingOrdersSkeleton.module.scss'

const orderSkeletons = [
  { name: 180, dateTime: 160, attendeeCount: 120 },
  { name: 240, dateTime: 180, attendeeCount: 100 },
  { name: 200, dateTime: 140, attendeeCount: 100 },
  { name: 220, dateTime: 140, attendeeCount: 120 },
]

interface UpcomingOrdersSkeletonProps {
  showOrderDate?: boolean
  showIcon?: boolean
}

export default function UpcomingOrdersSkeleton({
  showOrderDate,
  showIcon,
}: UpcomingOrdersSkeletonProps) {
  return (
    <div className={styles.container}>
      {orderSkeletons.map((order, index) => {
        const orderKey = `order-${index}`
        return (
          <div key={orderKey} className={styles.upcomingOrder}>
            {showIcon && <Skeleton className={styles.orderIcon} />}
            <div className={styles.orderInfo}>
              {showOrderDate && (
                <Skeleton
                  className={clsx(
                    styles.orderDateTime,
                    styles[`width${order.dateTime}`],
                  )}
                />
              )}
              <Skeleton
                className={clsx(styles.orderName, styles[`width${order.name}`])}
              />
              <Skeleton
                className={clsx(
                  styles.attendeeCount,
                  styles[`width${order.attendeeCount}`],
                )}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
