import { batch } from 'react-redux'
import { OrderFormat } from '@ancon/wildcat-types'
import { useCallback } from 'react'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import useTranslation from 'next-translate/useTranslation'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import Modal from '../../../app/components/modal/Modal'
import useDeviceSize from '../../../app/hooks/useDeviceSize'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { outletsSetSelectedOutletListItem } from '../../store/outletsSlice'
import { outletSetOutletDetails } from '../../../outlet/store/outletSlice'
import { outletsIsNewOrderModalVisibleSelector } from '../../store/outletsSelectors'
import { ModalHeaderProps } from '../../../app/components/modal/ModalHeader'

import NewOrderCard from './NewOrderCard'
import styles from './NewOrderCardModal.module.scss'

type NewOrderCardModalProps = {
  onClickOrderFormat: (orderFormat: OrderFormat) => void
}

export default function NewOrderCardModal({
  onClickOrderFormat,
}: NewOrderCardModalProps) {
  const { t } = useTranslation('outlets')
  const dispatch = useAppDispatch()
  const { isMobile } = useDeviceSize()
  const isVisible = useAppSelector(outletsIsNewOrderModalVisibleSelector)

  function handleOnClose() {
    batch(() => {
      dispatch(outletsSetSelectedOutletListItem(null))
      dispatch(outletSetOutletDetails(null))
    })
  }

  const CustomHeader = useCallback(
    ({ onClose }: ModalHeaderProps) => (
      <div className={styles.header}>
        <BodyText className={styles.headerText} color="body-2">
          {t('newOrderCard.newOrder')}
        </BodyText>
        <div
          className={styles.closeButton}
          onClick={onClose}
          role="presentation"
        >
          <CloseIcon />
        </div>
      </div>
    ),
    [t],
  )

  return (
    <Modal
      isOpen={isMobile && isVisible}
      mobileModalMode="bottom-sheet"
      onClose={handleOnClose}
      className={styles.modal}
      CustomModalHeader={CustomHeader}
    >
      <NewOrderCard isInModal onClickOrderFormat={onClickOrderFormat} />
    </Modal>
  )
}
