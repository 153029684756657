import useTranslation from 'next-translate/useTranslation'
import LinkIcon from '@ancon/wildcat-ui/shared/icons/link-outline.svg'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { CheckoutOrderType } from '@ancon/wildcat-types'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import { NewOrderStep } from '../../types'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  preOrderGoNextNewOrderStep,
  resetNewOrderDetails,
  setPreOrderUser,
} from '../../store/preOrderSlice'
import Input from '../../../app/components/Input'
import useClipboard from '../../../app/hooks/useClipboard'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderCreatedGroupOrderIdSelector,
  preOrderUpcomingOrderByIdSelector,
} from '../../store/preOrderSelectors'
import { LocalStorageCartMeta } from '../../../checkout/types'
import localStorageUtils from '../../../app/utils/localStorageUtils'
import useAppStore from '../../../../store/hooks/useAppStore'
import { outletsSelectedOutletListItemUrlSlugSelector } from '../../../outlets/store/outletsSelectors'
import getPreOrderPageURL from '../../utils/getPreOrderPageURL'
import storedPreOrderUserToken from '../../utils/storedPreOrderUserToken'
import {
  clientContextCustomerEmailSelector,
  clientContextCustomerNameSelector,
} from '../../../clientContext/store/clientContextSelectors'
import createPreOrderSharableLink from '../../utils/createPreOrderSharableLink'
import useIsRouteChanging from '../../../app/hooks/useIsRouteChanging'

import styles from './GroupOrderSharedLink.module.scss'

export default function GroupOrderSharedLink() {
  const { t, lang } = useTranslation('preOrder')

  const router = useRouter()

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const clipboard = useClipboard()

  const createdGroupOrderId = useAppSelector(
    preOrderCreatedGroupOrderIdSelector,
  )

  const isRouteLoading = useIsRouteChanging()

  const groupOrderLink = useMemo(
    () =>
      createPreOrderSharableLink(
        createdGroupOrderId!,
        CheckoutOrderType.GroupPreOrder,
        lang,
      ),
    [createdGroupOrderId, lang],
  )

  function handleProceedOrder() {
    const createdGroupOrder = preOrderUpcomingOrderByIdSelector(
      store.getState(),
      createdGroupOrderId!,
    )

    if (createdGroupOrder) {
      const { orderFormat, outlet, hostAttendeeId } = createdGroupOrder

      localStorageUtils.setItem<LocalStorageCartMeta>('cartMeta', {
        outletId: outlet.id,
        checkoutId: createdGroupOrderId!,
        isPreOrderCheckout: true,
      })

      if (hostAttendeeId) {
        const hostEmail = clientContextCustomerEmailSelector(store.getState())
        const hostName = clientContextCustomerNameSelector(store.getState())

        const preOrderUser = {
          id: hostAttendeeId!,
          name: hostName!,
          email: hostEmail!,
          isHost: true,
        }
        storedPreOrderUserToken.create(preOrderUser)
        dispatch(setPreOrderUser(preOrderUser))

        const urlSlug = outletsSelectedOutletListItemUrlSlugSelector(
          store.getState(),
        )

        if (urlSlug) {
          // Navigate to pre-order page
          router.push(getPreOrderPageURL(urlSlug, orderFormat))
        }
      }
    }
  }

  function handleDone() {
    dispatch(preOrderGoNextNewOrderStep(NewOrderStep.None))
    dispatch(resetNewOrderDetails())
  }

  async function handleCopyGroupOrderLink() {
    await clipboard.copyText(groupOrderLink)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.sharedLinkDetails}>
          <div className={styles.linkDetailsHeader}>
            <HeadingText as="h3" color="heading-1">
              {t('newOrder.almostDone')}
            </HeadingText>
            <HeadingText as="h2">{t('newOrder.shareTheLink')}</HeadingText>
          </div>
          <div className={styles.sharedLinkNote}>
            <BodyText as="label">{t('newOrder.copyThisLinkAndShare')}</BodyText>
          </div>
          <Input
            readOnly
            containerClassName={styles.sharedLink}
            value={groupOrderLink}
            leftAccessory={<LinkIcon />}
          />
          <Button
            variant="secondary"
            className={styles.copyButton}
            onClick={handleCopyGroupOrderLink}
          >
            <BodyText as="span">
              {clipboard.isCopied ? t('copied') : t('copyLink')}
            </BodyText>
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          size="large"
          onClick={handleProceedOrder}
          loading={isRouteLoading}
        >
          {t('newOrder.proceedToOrder')}
        </Button>
        <Button size="large" variant="secondary" onClick={handleDone}>
          {t('newOrder.done')}
        </Button>
      </div>
    </div>
  )
}
