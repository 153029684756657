import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

import BodyText from '../../app/components/BodyText'

import styles from './CalendarLegend.module.scss'

export type CalendarLegendProps = {
  highlightedDayLabel?: string
}

export default function CalendarLegend({
  highlightedDayLabel,
}: CalendarLegendProps) {
  const { t } = useTranslation('common')

  return (
    <div className={styles.legend}>
      {highlightedDayLabel && (
        <div className={styles.legendItem}>
          <div className={clsx(styles.marker, styles.highlighted)} />
          <BodyText color="body-1">{highlightedDayLabel}</BodyText>
        </div>
      )}
      <div className={styles.legendItem}>
        <div className={clsx(styles.marker, styles.today)} />
        <BodyText color="body-1">{t('calendar.today')}</BodyText>
      </div>
      <div className={styles.legendItem}>
        <div className={clsx(styles.marker, styles.selected)} />
        <BodyText color="body-1">{t('calendar.selectedDate')}</BodyText>
      </div>
    </div>
  )
}
