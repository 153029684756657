import useTranslation from 'next-translate/useTranslation'
import { useController } from 'react-hook-form'
import { OrderFormat } from '@ancon/wildcat-types'

import { NewOrderConfigurationFormState } from '../../types'
import { preOrderSupportedOrderFormatsSelector } from '../../store/preOrderSelectors'
import OutletOrderFormats from '../../../outlets/components/OutletOrderFormats'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import BodyText from '../../../app/components/BodyText'
import Accordion from '../../../app/components/Accordion'
import useToggleState from '../../../app/hooks/useToggleState'
import PreOrderIconDataField from '../PreOrderIconDataField'
import OrderFormatText from '../../../app/components/OrderFormatText'
import OrderTypeIcon from '../../../app/components/OrderTypeIcon'

import styles from './FormOrderFormatsAccordion.module.scss'

export default function FormOrderFormatsAccordion() {
  const { t } = useTranslation('preOrder')

  const supportedOrderFormats = useAppSelector(
    preOrderSupportedOrderFormatsSelector,
  )

  const [isExpanded, toggle] = useToggleState(false)

  const {
    field: { value, onChange },
  } = useController<NewOrderConfigurationFormState>({
    name: 'orderFormat',
    rules: { required: true },
  })

  return (
    <Accordion
      headerContainerClassName={styles.headerContainer}
      caretClassName={styles.caret}
      onToggle={toggle}
      expanded={isExpanded}
      title={
        <PreOrderIconDataField
          icon={<OrderTypeIcon orderFormat={value as OrderFormat} filled />}
          label={t('newOrder.orderAs')}
          placeholder={t('newOrder.setOrderFormat')}
          showCompletedIndicator
        >
          <OrderFormatText orderFormat={value as OrderFormat} />
        </PreOrderIconDataField>
      }
    >
      <div className={styles.orderFormatsContainer}>
        {supportedOrderFormats.length ? (
          <OutletOrderFormats
            className={styles.orderFormats}
            selectedOrderFormatClassName={styles.selectedOrderFormat}
            selectedOrderFormat={value as OrderFormat}
            orderFormats={supportedOrderFormats}
            onChange={onChange}
          />
        ) : (
          <div className={styles.emptyOrderFormats}>
            <BodyText color="body-2" fontSize="1.2rem">
              {t('newOrder.noOrderFormatsAvailable')}
            </BodyText>
          </div>
        )}
      </div>
    </Accordion>
  )
}
