import { OutletListItem } from '@ancon/wildcat-types'
import clsx from 'clsx'

import Card from '../../../layout/components/card/Card'
import isOutletsShowOutletBanner from '../../utils/isOutletsShowOutletBanner'
import OutletCardBanner from '../outletCard/OutletCardBanner'
import OutletCardOutletInfoSummary from '../outletCard/OutletCardOutletInfoSummary'

import styles from './RestaurantSuggestionCard.module.scss'

interface RestaurantSuggestionCardProps {
  outlet: OutletListItem
  selected?: boolean
  onClick?: (outlet: OutletListItem) => void
}

export default function RestaurantSuggestionCard({
  outlet,
  selected = false,
  onClick,
}: RestaurantSuggestionCardProps) {
  const showOutletBanner = isOutletsShowOutletBanner()

  function handleClickCard() {
    onClick?.(outlet)
  }

  return (
    <Card
      className={clsx(styles.container, { [styles.selected]: selected })}
      onClick={handleClickCard}
      tabIndex={0}
      aria-label={outlet.name}
    >
      {showOutletBanner && <OutletCardBanner outlet={outlet} />}
      <OutletCardOutletInfoSummary
        className={styles.outletInfo}
        outlet={outlet}
        showOutletStatus
      />
    </Card>
  )
}
