import useTranslation from 'next-translate/useTranslation'
import { useFormContext } from 'react-hook-form'

import BodyText from '../../../app/components/BodyText'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { preOrderSelectedRestaurantMaxAttendeeLimit } from '../../store/preOrderSelectors'

import styles from './GroupOrderMaxAttendeeLimitNote.module.scss'

export default function GroupOrderMaxAttendeeLimitNote() {
  const { t } = useTranslation('preOrder')

  const maximumAttendeeLimit = useAppSelector(
    preOrderSelectedRestaurantMaxAttendeeLimit,
  )

  const { watch } = useFormContext()

  const groupOrderSize = watch('groupOrderSize')

  if (!maximumAttendeeLimit || maximumAttendeeLimit >= groupOrderSize) {
    return null
  }

  return (
    <div className={styles.container}>
      <BodyText as="label">
        {t('newOrder.attendeeLimitNote', { maxLimit: maximumAttendeeLimit })}
      </BodyText>
    </div>
  )
}
